import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/border.css'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EN from "./locales/en/translation.json"
import JA from "./locales/ja/translation.json"
import ZH_CN from "./locales/zh-CN/translation.json"
import ZH_TW from "./locales/zh-TW/translation.json"
import ID from "./locales/id/translation.json"

import EN_QUESTIONS from "./locales/en/questions.json"
import JA_QUESTIONS from "./locales/ja/questions.json"
import ZH_CN_QUESTIONS from "./locales/zh-CN/questions.json"
import ZH_TW_QUESTIONS from "./locales/zh-TW/questions.json"
import ID_QUESTIONS from "./locales/id/questions.json"

import { setupSentry } from './util/sentry'

// Initialize Sentry.
setupSentry()

// ローカルストレージから言語設定を取得
let storedLanguage;
try {
  storedLanguage = localStorage.getItem('language');
} catch (e) {
  console.error(e);
}
const initialLanguage = storedLanguage ? storedLanguage : 'ja';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ja',
    lng: initialLanguage,

    resources: {
      "en": {
        translation: EN,
        questions: EN_QUESTIONS,
      },
      "ja": {
        translation: JA,
        questions: JA_QUESTIONS,
      },
      "zh-CN": {
        translation: ZH_CN,
        questions: ZH_CN_QUESTIONS,
      },
      "zh-TW": {
        translation: ZH_TW,
        questions: ZH_TW_QUESTIONS,
      },
      "id": {
        translation: ID,
        questions: ID_QUESTIONS,
      },
    },

    defaultNS: 'translation',

    interpolation: {
      escapeValue: false,
    }
  })

// htmlの言語設定を変更する
document.documentElement.setAttribute('lang', initialLanguage);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Suspense fallback="Loading">
      <App />
    </Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
