import './MyTippy.css'

import Tippy from '@tippyjs/react'
import maxSize from 'popper-max-size-modifier'

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    // The `maxSize` modifier provides this data
    const { width, height } = state.modifiersData.maxSize

    state.styles.popper = {
      ...state.styles.popper,
      maxWidth: `${width}px`,
      maxHeight: `${height}px`,
      overflowY: 'auto',
    }
  }
}

const defaultProps = {
  arrow: true,
  needWrapper: false,
  wrapperClass: '',
  wrapperDisplay: 'inline-block',
  theme: 'donut',
  onCreate: () => { },
}

/**
 * @typedef {import('tippy.js').Instance} Instance
 */

/**
 * @param {{
 *   needWrapper?: boolean;
 *   wrapperClass?: string;
 *   wrapperDisplay?: string;
 *   content: string | object;
 *   appendTo?: string | HTMLElement;
 *   touch?: boolean | string | [string, number];
 *   placement?: string;
 *   arrow?: boolean;
 *   trigger?: string;
 *   theme?: string;
 *   offset?: number[];
 *   hideOnClick?: boolean;
 *   onShow?: (tippy: Instance) => void;
 *   onHide?: (tippy: Instance) => void;
 *   onCreate?: (tippy: Instance) => void;
 *   onDestroy?: (tippy: Instance) => void;
 *   singleton?: any;
 *   enableMaxSize?: boolean;
 *   delay?: (number|null)[];
 *   children: any;
 * }} props
 */
function MyTippy(props) {
  const { children, needWrapper, wrapperClass, wrapperDisplay, enableMaxSize } = props

  const cloned = { ...defaultProps, ...props }
  delete cloned.children
  delete cloned.needWrapper
  delete cloned.wrapperClass
  delete cloned.wrapperDisplay
  delete cloned.enableMaxSize

  if (typeof cloned.content === 'string') {
    // text content
    cloned.touch = 'hold'
  } else {
    // html content

    // interactive by default
    cloned.interactive = cloned.interactive !== undefined ? cloned.interactive : true
    cloned.trigger = cloned.trigger || 'mouseenter click'
    if (!cloned.arrow) {
      // no offset if arrow is not showing
      cloned.offset = cloned.offset || [0, 0]
    }
  }

  return (
    <Tippy {...cloned} popperOptions={enableMaxSize ? {
      modifiers: [maxSize, applyMaxSize],
    } : {}}>
      {needWrapper ?
        <span style={{ display: wrapperDisplay || 'flex' }} className={wrapperClass}>
          {children}
        </span>
        :
        children
      }
    </Tippy>
  )
}

export default MyTippy
