import * as Sentry from '@sentry/react';
import Axios, { AxiosError } from 'axios';

let isSentryEnabled = false;

export const setupSentry = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL as string
    const baseUrlRegex = new RegExp(`^${BASE_URL.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}`);
    const ENV = process.env.REACT_APP_ENVIRONMENT || 'development';
    const SENTRY_DNS = process.env.REACT_APP_SENTRY_DSN;

    // Report issue from both production and staging, but not development.
    if (ENV !== 'development' && SENTRY_DNS) {
        isSentryEnabled = true;
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: ENV,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                baseUrlRegex,
                /^wss:\/\/speaker_diarization_184018590\.ichizero-gpt\.com\/transcribe/,
                /^https:\/\/japaneast\.tts\.speech\.microsoft\.com\/cognitiveservices\/v1/
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        console.log("sentry was initialized")
    }
}

export const sentrySetUser = (clientId: string, meetingId: string) => {
    if (isSentryEnabled){
        Sentry.setUser({ id: clientId, meetingId: meetingId, host: window.location.host });
    }
}

export function sentryLog(err: Error | AxiosError) {
    console.error(err);

    if (!isSentryEnabled) return;

    if (Axios.isCancel(err)) return;

    if (isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let contexts = {};
        const response = axiosError.response;
        const endpoint = response?.config.url || '';
        const status = response?.status;
        const method = response?.config.method || '';

        contexts = { response };

        Sentry.withScope((scope) => {
            scope.setFingerprint(['{{ default }}', endpoint, String(status), method]);
            Sentry.captureException(axiosError, {
                contexts,
            });
        });
    } else {
        Sentry.captureException(err);
    }
}

const isAxiosError = (error: any): error is AxiosError => {
    return error.isAxiosError === true;
}
