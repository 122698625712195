import './App.css'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import React, { MutableRefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import UserInfo from './store/UserInfo'
import HomePage from './page/home/HomePage'
import LoginPage from './page/login/LoginPage'
import { Contact } from './page/contact/Contact'
import { Questions } from './page/questions/Questions'
import { Policies } from './page/policies/Policies'
import { Contract } from './page/policies/Contract'
import { PrivacyPolicy } from './page/policies/PrivacyPolicy'
import Logo from './logo.png'
import Popup from './view/Popup'
import { EmailConfirm } from './page/emailConfirm/EmailConfirm'
import { ResetPassword } from './page/resetPassword/ResetPassword'
import LoadingButton from './view/LoadingButton'
import { createMeetingUrl } from './api/MeetingApi'
import { BASE_ROUTES } from './Routes'
import { BUTTON_MODES, SIZE_MODE, show, hide } from './view/PopupEvent'
import { className } from './util/className'
import TimeCounter from './view/TimeCounter'
import { Subscribe } from './page/subscribeForm/Subscribe'

import { LoadingOverlay } from './view/LoadingOverlay';
import { convertDateTimeToString, calcDiffMinutes } from "./util/date";
import { sentryLog } from "./util/sentry";
import { FileUpload, FileUploadHandle } from './view/FileUpload';

const paramShareWindow = 'share';

/**
 * This component is only used for exposing history object of react-router-dom, for navigating after meeting created.
 */
const ForNavigation = ({ historyRef }: { historyRef: MutableRefObject<any> }) => {
  const history = useHistory()
  historyRef.current = history
  return null
}


function FilterTime(n: number) {
  return UserInfo.isUnlimitedPlan() || (n * 60 <= UserInfo.gpTechRemainingMeetingTime)
}


export const MeetingCreateModal = observer((
  { historyRef, setCreating }: { historyRef: MutableRefObject<any>, setCreating: (b: boolean) => void }
) => {
  const { t } = useTranslation()

  const MIN_MEETING_DURATION = 1;
  const DEFAULT_MAX_MEETING_DURATION = 300; // Minutes Count. default 5 hours.

  const userRemainingMeetingDuration = UserInfo.isUnlimitedPlan() ? Infinity : UserInfo.gpTechRemainingMeetingTime
  const maxMeetingDuration = Math.min(Math.max(0, Math.floor(userRemainingMeetingDuration / 60)), 60 * 5) // Minutes Count. Max -- 5 hours.

  const now = new Date();
  const initialBeginAt = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0);

  const [title, setTitle] = useState(`${UserInfo.displayName}${t('の会議')} ${new Date().toLocaleDateString()}`)
  const [allocatedMinutes, setAllocatedMinutes] = useState(60)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isActive, setIsActive] = useState(false);
  const [scheduledBeginAt, setScheduledBeginAt] = useState(convertDateTimeToString(initialBeginAt));
  const [scheduledEndAt, setScheduledEndAt] = useState(convertDateTimeToString(initialBeginAt, allocatedMinutes));
  const [maxEndAt, setMaxEndAt] = useState(convertDateTimeToString(initialBeginAt, maxMeetingDuration));
  const [enableAIAdvisor, setEnableAIAdvisor] = useState(false); // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）

  const selectOptionRef = useRef<HTMLSelectElement>(null)

  const agendaFileUploadRef = useRef<FileUploadHandle>(null)
  const advanceDocumentsFileUploadRef = useRef<FileUploadHandle>(null)

  const validateAndSetInputBeginAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.currentTarget.value);
    const beginAt = new Date(convertDateTimeToString(date, allocatedMinutes));

    setScheduledBeginAt(convertDateTimeToString(date));
    setScheduledEndAt(convertDateTimeToString(beginAt));
    setMaxEndAt(convertDateTimeToString(beginAt, maxMeetingDuration));
    setAllocatedMinutes(calcDiffMinutes(date, beginAt));

    if (selectOptionRef.current) {
      const element: HTMLSelectElement = selectOptionRef.current;
      element.value = "automatic"
    }
  }

  const validateAndSetInputEndAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.currentTarget.value);
    const beginAt = new Date(scheduledBeginAt);
    if(date < beginAt){
      alert(t('開始時刻より未来の日時を選択してください'));
      return
    }
    if(date > new Date(maxEndAt)){
      if(maxMeetingDuration === DEFAULT_MAX_MEETING_DURATION){
        alert(t('会議の最大時間は5時間です。5時間以内の日時を選択してください'));
      }else{
        alert(t('会議の残り時間を超えています'));
      }
      return
    }
    setScheduledEndAt(convertDateTimeToString(date));
    setAllocatedMinutes(calcDiffMinutes(beginAt, date))
    if (selectOptionRef.current) {
      const element: HTMLSelectElement = selectOptionRef.current;
      element.value = "automatic"
    }
  }

  const validateAndSetAllocatedMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value)
    if (Number.isInteger(value) && MIN_MEETING_DURATION <= value && value <= maxMeetingDuration) {
      if (selectOptionRef.current) {
        const element: HTMLSelectElement = selectOptionRef.current;
        element.value = "automatic"
      }

      setAllocatedMinutes(value)
      setScheduledEndAt(convertDateTimeToString(new Date(scheduledBeginAt), value));
    }
  }

  const validateAndSetSelectedMinutes = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.currentTarget.value)
    if (Number.isInteger(value) && MIN_MEETING_DURATION <= value && value <= maxMeetingDuration) {
      setAllocatedMinutes(value)
      setScheduledEndAt(convertDateTimeToString(new Date(scheduledBeginAt), value));
    }
  }

  const meetingAlreadyExistsMessage = i18next.t('ミーティングが既に存在しています。')

  // OpenAI Vector Store API で対応しているデータの種類
  // ※ docx, pdf, txt のみ対応という要件のため、それ以外はコメントアウト
  const acceptFile = {
    //'c': 'text/x-c',
    //'cs': 'text/x-csharp',
    //'cpp': 'text/x-c++',
    //'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //'html': 'text/html',
    //'java': 'text/x-java',
    //'json': 'application/json',
    //'md': 'text/markdown',
    'pdf': 'application/pdf',
    //'php': 'text/x-php',
    //'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    //'py': 'text/x-python',
    //'rb': 'text/x-ruby',
    //'tex': 'text/x-tex',
    'txt': 'text/plain',
    //'css': 'text/css',
    //'js': 'text/javascript',
    //'sh': 'application/x-sh',
    //'ts': 'application/typescript'
  };

  // 受け入れられるファイル個数かどうか確認する
  const validateUploadFileCounts = (files: FileList | null | undefined, limit: number) => {
    // ファイルがなければ終了
    if (!files || files?.length === 0) return true;

    if (files.length > limit) {
      alert(i18next.t('同時にアップロード可能なファイル個数の上限は{{limit}}個に設定されています', {limit: limit}));
      return false;
    }
    return true;
  }

  // 受け入れられるファイルサイズかどうか確認する
  const validateUploadFileSize = (files: FileList | null | undefined, limit: number) => {
    // ファイルがなければ終了
    if (!files || files?.length === 0) return true;
    // ファイルを取得
    const filesArray = Array.from(files);
    let total = 0;
    for (const file of filesArray) {
      total += file.size;
      if (total >= limit) {
        alert(i18next.t('アップロード可能なファイルサイズは{{limit}}未満に設定されています', {limit: (limit / (1024 * 1024)).toFixed(0) + "MB"}));
        return false;
      }
    }
    return true;
  }

  // ファイルの拡張子で受け入れられるファイルかどうか確認する
  const validateUploadFileExtension = (files: FileList | null | undefined, acceptFile: string[]) => {
    // ファイルがなければ終了
    if (!files || files?.length === 0) return true;
    // ファイルを取得
    const filesArray = Array.from(files);
    for (const file of filesArray) {
      const ext = file.name.split(".").at(-1);
      if (!ext || !acceptFile.includes(ext)) {
        alert(i18next.t('このファイルは非対応です'));
        return false;
      }
    }
    return true;
  }

  const validateUploadAdvanceDocuments = (files: FileList | null | undefined) => {
    // ファイル個数
    if (!validateUploadFileCounts(files, 10)) {
      return false;
    }
    // ファイルサイズ
    if (!validateUploadFileSize(files, 10485760)) {
      return false;
    }
    // ファイル形式
    if (!validateUploadFileExtension(files, Object.keys(acceptFile))) {
      return false;
    }
    return true;
  }

  const validateUploadAgenda = (files: FileList | null | undefined) => {
    // ファイル個数
    if (!validateUploadFileCounts(files, 1)) {
      return false;
    }
    // ファイルサイズ
    if (!validateUploadFileSize(files, 1048576)) {
      return false;
    }
    // ファイル形式
    if (!validateUploadFileExtension(files, ["txt"])) {
      return false;
    }
    return true;
  }

  const handledAdvanceDocumentsFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    return validateUploadAdvanceDocuments(files);
  }

  const handleAgendaFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    return validateUploadAgenda(files);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsDisabled(true)

    // 不正な状態・値の場合は送信しない
    if(isDisabled){
      return;
    }
    if(!e.currentTarget.checkValidity()){
      return;
    }
    if(!Number.isInteger(allocatedMinutes)){
      return;
    }
    if(!validateUploadAgenda(agendaFileUploadRef.current?.getFiles())){
      return;
    }
    if(!validateUploadAdvanceDocuments(advanceDocumentsFileUploadRef.current?.getFiles())){
      return;
    }

    setIsActive(true)
    e.stopPropagation()
    e.preventDefault()

    createMeetingUrl(title, scheduledBeginAt, scheduledEndAt, allocatedMinutes * 60, enableAIAdvisor, agendaFileUploadRef.current?.getFiles(), advanceDocumentsFileUploadRef.current?.getFiles()).then(resp => {
      if (resp.data.success) {
        historyRef.current?.push(BASE_ROUTES.LIST)
        // Simply Update it locally for now.
        // Remote data isn't far off from this

        UserInfo.refreshTime().then(() => hide())
      } else {
        window.alert(resp.data.message || meetingAlreadyExistsMessage)
      }
    }).catch(err => {
      // log to sentry
      sentryLog(err)
      if (err.response && err.response.data) {
        if (err.response.data.message) {
          window.alert(err.response.data.message || meetingAlreadyExistsMessage)
        }
      } else if (typeof err === 'string') {
        window.alert(err)
      }
    }).finally(() => {
      setIsDisabled(false)
      setCreating(false)
      setIsActive(false)
    })
  }

  const buttonClassName = className("flex-shrink-1 btn", {
    "btn-success": !isDisabled,
    "btn-secondary": isDisabled,
  })

  const selectMinuteOptions: Array<number> = [
    15,
    30,
    60,
    90,
    120,
    150,
    180,
    210,
    240,
    270,
    300,
  ]

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="mb-3 row">
        <label htmlFor="title" className="col-sm-4 col-form-label">
          {t('タイトル')}
        </label>
        <div className="col-sm-8">
          <input
            required
            id="title"
            type="text"
            value={title}
            className="form-control"
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="scheduledtBeginAt" className="col-sm-4 col-form-label">
          {t('開始時刻')}
        </label>

        <div className="col-sm-8">
          <input
            required
            id="scheduledtBeginAt"
            className="form-control"
            type="datetime-local"
            value={scheduledBeginAt}
            onChange={validateAndSetInputBeginAt}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="inputEndAt" className="col-sm-4 col-form-label">
          {t('終了時刻')}
        </label>

        <div className="col-sm-8">
          <input
            required
            id="inputEndAt"
            className="form-control"
            type="datetime-local"
            value={scheduledEndAt}
            onChange={validateAndSetInputEndAt}
            min={scheduledBeginAt}
            max={maxEndAt}
          />
        </div>
      </div>

      <div className="mb-3">
        <div className="row">
          <label htmlFor="allocatedTime" className="col-sm-4 col-form-label">
            {t('割り当て時間(分)')}
          </label>

          <label htmlFor="allocatedTime" className="col-sm-8 col-form-label">
            {allocatedMinutes} {t('分')}
          </label>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <select
              ref={selectOptionRef}
              className="form-select"
              onChange={validateAndSetSelectedMinutes}
            >
              <option selected value="automatic">
                {t('手動設定')} ({allocatedMinutes} {t('分')})
              </option>
              {selectMinuteOptions
                .filter(FilterTime)
                .map((time =>
                    <option value={time}>
                      <TimeCounter second={time * 60} prefix='' />
                    </option>
                ))
              }
            </select>
          </div>
        </div>

        <input
          type="range"
          min={MIN_MEETING_DURATION}
          max={maxMeetingDuration}
          id="allocatedTime"
          className="form-range"
          value={allocatedMinutes}
          onChange={validateAndSetAllocatedMinutes}
        />

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="enableAIAdvisor"
            checked={enableAIAdvisor}
            onChange={(e) => setEnableAIAdvisor(e.currentTarget.checked)}
          />
          <label className="form-check-label" htmlFor="enableAIAdvisor">
            {t('AIアドバイザーを使用する')}
          </label>
        </div>

        {/* 本番環境ではない場合は使用可能 本番環境の場合はアンリミテッドプランLのみ使用可能 */}
        {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="agendaFileUpload" className="col-form-label">
                {t('アジェンダのアップロード')}
              </label>
              <FileUpload
                ref={agendaFileUploadRef}
                onFileChange={handleAgendaFileChange} />
            </div>
          </div>
        )}

        {/* 本番環境ではない場合は使用可能 本番環境の場合はアンリミテッドプランLのみ使用可能 */}
        {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="advanceDocumentsFileUpload" className="col-form-label">
                {t('事前資料のアップロード')}
              </label>
              <FileUpload
                ref={advanceDocumentsFileUploadRef}
                accept={Object.values(acceptFile).join(',')}
                multiple={true}
                onFileChange={handledAdvanceDocumentsFileChange} />
            </div>
          </div>
        )}
      </div>

      <div className="d-flex">
        <div className="flex-fill"></div>
        <button
          type="submit"
          className={buttonClassName}
          disabled={isDisabled}
        >
          {t('作成')}
        </button>
      </div>
      <LoadingOverlay active={isActive} />
    </form>
  )
})

const TimeCounterForRemainingTime = observer(() => {
  const { t } = useTranslation()

  return UserInfo.isUnlimitedPlan() ? (
    <span className='text-danger'>{t('時間無制限')}</span>
  ) : (
    <TimeCounter second={UserInfo.currentSub?.remainingTime || 0} />
  )
})

const LanguageOptions = () => {
  const { i18n } = useTranslation();
  const onChange = (lang: string) => {
    // 言語設定変更
    i18n.changeLanguage(lang);
    // htmlの言語設定も変更する
    document.documentElement.setAttribute('lang', lang);
    // ローカルストレージに保管
    localStorage.setItem('language', lang);
  };
  const language = i18n.language; // This value ONLY changes when you pick onChange above. it's safe.

  return (
    <select onChange={(val) => onChange(val.currentTarget.value)} value={language}>
      <option lang='ja' value='ja'>日本語</option>
      <option lang='en' value='en'>English</option>
      <option lang='zh-CN' value='zh-CN'>简体中文</option>
      <option lang='zh-TW' value='zh-TW'>繁體中文</option>
      <option lang='id' value='id'>Bahasa Indonesia</option>
    </select>
  )
}

function App() {
  const [creating, setCreating] = useState(false)
  const { t } = useTranslation()

  const historyRef = useRef<any>()

  // オーナーIDを「clipear-meeting-web」に送信
  const params = new URLSearchParams(window.location.search);
  const paramShare = params.get(paramShareWindow);
  if(paramShare === '1' && UserInfo.loggedIn && UserInfo.id && process.env.REACT_APP_MEETING_URL){
    const url = new URL(process.env.REACT_APP_MEETING_URL);
    window.parent.postMessage({ownerId: UserInfo.id}, url.origin);
  }

  const handleCreateUrl = () => {
    show({
      content: <MeetingCreateModal
        historyRef={historyRef}
        setCreating={setCreating}
      />,
      title: t("新しく会議を作る"),
      size: SIZE_MODE.MEDIUM,
      btnMode: BUTTON_MODES.NONE,
    })
  }

  return (
    <div className='App'>
      <div className='home-page'>
        <div className='header donut-box-thick p-2'>
          <div className='flex-grow-1'>
            <img className='logo' src={Logo} alt='Donut Robotics' />
          </div>

          <div className='flex-shrink-1'>
            <TimeCounterForRemainingTime />
          </div>
          <div className='col-auto text-primary row ms-auto'>
            <div className='col-auto text-ellipsis'>
              <div className='text-link'>
                {/*<b><i className='bi bi-chevron-right' /> <small>利用規約・プライバシーポリシー</small></b>*/}

                {UserInfo.loggedIn && (
                  <div className='col-auto'>
                    <LoadingButton className='rounded-pill d-none d-md-block'
                                   isLoading={creating} onClick={handleCreateUrl} big>
                      {t('ミーティングを新規作成')}
                    </LoadingButton>
                    <LoadingButton className='rounded-pill d-block d-md-none'
                                   isLoading={creating} onClick={handleCreateUrl}>
                      {t('ミーティングを新規作成')}
                    </LoadingButton>
                  </div>
                )}
              </div>
            </div>
            <div className='col-auto text-ellipsis'>
              {!UserInfo.loggedIn && (
                <button onClick={() => historyRef.current?.push('/contact')} className='btn btn-link'>
                  <b><i className='bi bi-envelope' /> <small>
                    {t('お問い合わせ')}
                  </small></b>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='content'>
          <div>
            <Router>
              <Route path='' exact={false}>
                <ForNavigation historyRef={historyRef} />
              </Route>

              {UserInfo.loggedIn === true && (
                <Switch>
                  <Route path='/contract' exact={true} component={Contract} />
                  <Route path='' exact={false} component={HomePage} />
                </Switch>
              )}
              {UserInfo.loggedIn === false && (
                <Switch>
                  <Route path='/email-confirmation' exact={true} component={EmailConfirm} />
                  <Route path='/reset-password' exact={true} component={ResetPassword} />
                  <Route path='/policies' exact={true} component={Policies} />
                  <Route path='/questions' exact={true} component={Questions} />
                  <Route path='/contract' exact={true} component={Contract} />
                  <Route path='/contact' exact={true} component={Contact} />
                  <Route path='/policies' exact={true} component={Policies} />
                  <Route path='/privacy' exact={true} component={PrivacyPolicy} />
                  <Route path={BASE_ROUTES.SUBSCRIBE} exact={true} component={Subscribe} />
                  <Route path='' exact={false} component={LoginPage} />
                </Switch>
              )}
              <Popup />
            </Router>
          </div>
        </div>
        <div className='text-primary row p-2 align-items-center'>
          <div className='col-auto text-ellipsis'>
            <LanguageOptions />
          </div>
          <div className='col-auto text-ellipsis'>
            <button onClick={() => historyRef.current?.push('/policies')} className='btn btn-link'>
              <b><small>{t('利用規約')}</small></b>
            </button>
          </div>
          <div className='col-auto text-ellipsis'>
            <button onClick={() => historyRef.current?.push('/privacy')} className='btn btn-link'>
              <b><small>{t('プライバシーポリシー')}</small></b>
            </button>
          </div>
          <div className='col-auto text-ellipsis'>
            <button onClick={() => historyRef.current?.push('/subscribe')} className='btn btn-link'>
              <b><small>{t('プラン一覧')}</small></b>
            </button>
          </div>
          <div className='col-auto text-ellipsis'>
            <button onClick={() => historyRef.current?.push('/questions')} className='btn btn-link'>
              <b><small>{t('よくある質問')}</small></b>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default observer(App)
