export const USER_LOGIN = '/gpTech/signIn'
export const USER_LOGOUT = '/gpTech/signOut'
export const CHECK_SESSION = '/gpTech/user/me'

export const DESCRIBE_ME = '/gpTech/user/me'
export const UPDATE_USER = '/gpTech/user/name'
export const UPDATE_EMAIL = '/gpTech/user/email'
export const UPDATE_PASSWORD = '/gpTech/user/password'
export const DELETE_ACCOUNT = '/gpTech/user/delete'
export const GET_LAST_CARD_INFO = '/gpTech/user/last-card-info'

export const MEETING_CREATE_URL = '/gpTech/room'
export const MEETING_BEGIN_URL = '/gpTech/room/begin'
export const MEETING_FINISH_URL = '/gpTech/room/finish'

export const USER_DATA_VALIDATION = '/gpTech/registerCheck'
export const USER_CREATE = '/gpTech/register'
export const CONFIRM_EMAIL = '/gpTech/confirmEmail'
export const RESEND_EMAIL_CONFIRMATION = '/gpTech/resendConfirmEmail'
export const FORGOT_PASSWORD = '/gpTech/forgotPassword'
export const RESET_PASSWORD = '/gpTech/resetPassword'

export const GET_MEMBER_LIMIT_PRICES = '/gpTech/user/member-limit/price'
export const CHANGE_MEMBER_LIMIT = '/gpTech/user/member-limit/change'
export const CANCEL_MEMBER_LIMIT = '/gpTech/user/member-limit/cancel'

export const FETCH_MEMBERS = '/gpTech/user/members'
export const CREATE_MEMBER = '/gpTech/user/members'
export const DELETE_MEMBER = '/gpTech/user/members/{id}'
export const UPDATE_MEMBER = '/gpTech/user/members/{id}'
export const UPDATE_MEMBER_PASSWORD = '/gpTech/user/members/{id}/password'
export const REACTIVATE_MEMBER = '/gpTech/user/members/{id}/activate'

export const LIST_OF_AVAILABLE_PLANS = '/gpTech/plan'
export const SUBSCRIBE_TO_PLAN = '/gpTech/plan/subscribe'
export const UNSUBSCRIBE_FROM_PLAN = '/gpTech/plan/unsubscribe'
export const RESUME_SUBSCRIPTION = '/gpTech/plan/resume'
export const SEND_AN_INQUIRY = '/gpTech/contact'

export const GET_RESELLER_INFO = '/gpTech/reseller/{id}'
export const GET_RESELLER_PLANS = '/gpTech/reseller/{id}/plans'
