import './FileUpload.css'
import { observer } from 'mobx-react-lite'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FileUploadHandle = {
  getFiles: () => FileList | null | undefined,
}

type FileUploadProps = {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => boolean,
  accept?: string,
  multiple?: boolean,
  required?: boolean,
}

export const FileUpload = observer(forwardRef<FileUploadHandle, FileUploadProps>((
  {onFileChange, accept, multiple, required} , ref) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const fileRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    const valid = files && onFileChange(e);
    if (valid) {
      let names = '';
      for (let i = 0; i < files.length; i++) {
        if (names !== '') names += ', ';
        names += files[i].name;
      }
      setName(names);
    } else {
      setName('');
      if (fileRef.current) {
        fileRef.current.value = '';
      }
    }
    return valid;
  };

  useImperativeHandle(ref, () => ({
    getFiles: () => fileRef.current?.files,
  }));

  return (
    <>
      <div
        className="input-group custom-file-input-fake"
        onClick={() => {
          if (fileRef.current) fileRef.current.value = ''; // Reset the file input
          fileRef.current?.click();
        }}>
        <span className="input-group-text">{t('ファイル選択')}</span>
        <input
          type="text"
          className="form-control"
          placeholder={t('選択されていません')}
          value={name}
          readOnly={true} />
      </div>
      <input
        className="custom-file-input"
        ref={fileRef}
        type="file"
        accept={accept}
        multiple={multiple}
        required={required}
        onChange={handleFileChange} />
    </>
  )
}))
