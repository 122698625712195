import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import UserInfo from "../../store/UserInfo"
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = observer(() => {
  const { t } = useTranslation()

  return (
  <div className="overflow-y-auto text-donut p-4">
    {UserInfo.loggedIn
      ? (
        null
      )
      : (
        <Link to="/" className="mx-2 link-secondary">
          {t('戻る')}
        </Link>
      )}

    <h1 className="h3 mb-4 text-center">{t('プライバシーポリシー')}</h1>
    <p>ドーナッツロボティクス株式会社（以下「当社」といいます。）は、当社が提供する、リアルタイムでの会議の文字起こし、翻訳、議事録の自動生成等を可能にするサービス「donut AI(議事録ver.)」（以下「本サービス」といいます。）を利用するユーザー、外部ユーザー及び契約事業者（いずれも本サービスの利用規約第2条に定義され、以下「利用者」と総称します。）の情報について、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）のとおり取り扱います。<br />
      本サービスの利用を希望する皆様は、本プライバシーポリシーに同意の上、本サービスをご利用ください。なお、EUからご利用される場合には、「EU Customers Privacy Policy」をご確認ください。<br />
      なお、本文中の用語の定義は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）及び関係法令によります。</p>

    <h2 className="h4 mb-4">1.個人情報の取得</h2>
    <p>当社は、個人情報（個人情報保護法第2条第1項により定義される個人情報を意味します。以下同様です。）を含め以下の情報を業務上必要な範囲において適法かつ適正な手段により取得します。当社が取得する個人情報には、その収集方法に応じて、以下の情報が含まれます。</p>
    <ol>
      <li>ユーザーに関する情報</li>
      <ol>
        <li>ユーザーの本サービスの登録に際して取得する情報</li>
        <ul>
          <li>ユーザーの氏名、性別、生年月日、住所、事業内容その他のユーザーに関する基本情報</li>
          <li>ユーザーの電話番号、メールアドレスその他の連絡先に関する情報</li>
          <li>ユーザーが本サービスにおいて利用するID、パスワード等のログイン情報</li>
          <li>ユーザーの銀行口座情報その他の決済に関する情報</li>
          <li>当社が協業する事業者の製品を利用している場合、当該製品の製造番号</li>
        </ul>
        <li>ユーザーによる本サービスの利用に関連して取得する情報</li>
        <ul>
          <li>ユーザーにより行われる会議の日時、会議における発言内容、参加者その他の会議に関する一切の情報</li>
          <li>ユーザーが使用する言語に関する情報</li>
          <li>ユーザーの当社へのお問い合わせ、ご連絡等に関する情報</li>
        </ul>
        <li>ユーザーによる本サービスの利用状況に関する情報</li>
        <ul>
          <li>本サービスのアクセス履歴、利用履歴に関する情報</li>
          <li>Cookie情報、IPアドレス、端末情報、OS情報、ブラウザ情報その他ユーザーの通信に関する情報</li>
        </ul>
      </ol>
      <li>契約事業者に関する情報</li>
      <ol>
        <li>契約事業者の本サービスの登録に際して取得する情報</li>
        <ul>
          <li>契約事業者の名称（契約事業者が個人の場合は氏名又は屋号、法人の場合は商号を意味します。）、住所、事業内容その他の契約事業者に関する基本情報</li>
          <li>（契約事業者が法人の場合）代表者の氏名、生年月日、性別、住所その他の特定の個人を識別することができる情報</li>
          <li>契約事業者の電話番号、メールアドレスその他の連絡先に関する情報</li>
          <li>契約事業者の銀行口座情報その他の決済に関する情報</li>
        </ul>
        <li>契約事業者による本サービスの利用に関連して取得する情報</li>
        <ul>
          <li>契約事業者の当社へのお問い合わせ、ご連絡等に関する情報</li>
        </ul>
      </ol>
      <li>外部ユーザーに関する情報</li>
      <ol>
        <li>外部ユーザーによる本サービスの利用に関連して取得する情報</li>
        <ul>
          <li>外部ユーザーが招待され参加した会議の日時、会議における発言内容、参加者その他の会議に関する一切の情報</li>
          <li>外部ユーザーが使用する言語に関する情報</li>
          <li>外部ユーザーの当社へのお問い合わせ、ご連絡等に関する情報</li>
        </ul>
        <li>外部ユーザーによる本サービスの利用状況に関する情報</li>
        <ul>
          <li>本サービスのアクセス履歴、利用履歴に関する情報</li>
          <li>Cookie情報、IPアドレス、端末情報、OS情報、ブラウザ情報その他外部ユーザーの通信に関する情報</li>
        </ul>
      </ol>
    </ol>

    <h2 className="h4 mb-4">2.個人情報の利用目的</h2>
    <p>当社は、個人情報について、以下の利用目的の範囲内で利用し、利用者の同意がある場合又は法令で認められている場合を除き、他の目的で利用しません。</p>
    <ol>
      <li>本サービスへの登録、本サービス利用時の本人認証その他の利用者の管理</li>
      <li>本サービスの提供（会議における発言内容のテキスト化を含みますが、これに限られません。）</li>
      <li>本サービスの対価の請求</li>
      <li>不正行為等の防止及び対応</li>
      <li>本サービスの保守、運用、管理等</li>
      <li>マーケティングデータの調査及び分析並びにマーケティング施策の検討及び実施</li>
      <li>当社又は第三者の媒体における、利用者の属性・利用履歴の分析に基づく興味及び関心等にあわせた、当社又は第三者の商品等及びサービスの提供、勧誘、広告その他のマーケティング</li>
      <li>キャンペーン、アンケートの実施</li>
      <li>本サービスの改善</li>
      <li>当社の新商品・サービスの企画、開発等</li>
      <li>お問い合わせ対応及び利用者に対する連絡</li>
      <li>本プライバシーポリシー記載の方法による第三者に対する提供</li>
    </ol>

    <h2 className="h4 mb-4">3.個人情報の処理に関する委託</h2>
    <p>当社は、業務を円滑に進め、また、より良いサービスを提供するため、「2．個人情報の利用目的」の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を外部に委託する場合があります。ただし、委託する個人情報は、委託する業務を遂行するのに必要最小限の情報に限定します。</p>

    <h2 className="h4 mb-4">4.個人情報の第三者提供</h2>
    <p>当社は、「3. 個人情報の処理に関する委託」若しくは本条に定める場合、利用者の事前同意がある場合又は法令で認められている場合その他以下に定める場合を除き、個人情報を第三者に提供いたしません。</p>
    <ol>
      <li>代金決済、利用者から当社に対する問い合わせへの対応その他本サービスの運営及び提供のために、決済代行業者、業務委託先その他の第三者に提供する場合</li>
      <li>利用者が本サービスの利用規約、プライバシーポリシーその他当社との間で契約した契約上の定めに違反し、当社の権利、財産や本サービス等を保護するために、個人情報の提供が必要と判断した場合</li>
      <li>本サービスの利用者その他第三者の権利や利益等を守るため又は第三者との紛争を解決するために個人情報の提供が必要と判断した場合</li>
      <li>当社の株式の譲渡、合併等の組織再編行為、事業譲渡その他これらに類する取引の実行及びこれに先立って行われる調査や交渉に際して個人情報を開示する場合</li>
      <li>当社や第三者の広告の配信又は表示のために利用者に関する情報の提供が必要と判断した場合</li>
      <li>当社や第三者の提供するサービス内に本サービスの情報を配信若しくは表示するために利用者に関する情報の提供が必要と判断した場合</li>
      <li>「6．Cookieその他の技術の利用」に従い情報収集モジュール提供者へ個人情報が提供される場合</li>
    </ol>
    <p>当社は、次の外国にある第三者に取得情報を提供する場合があります。この場合に参考となるべき国別の情報は、現時点において、次に掲げるとおりです。</p>
    <table className="table">
      <thead>
        <tr>
          <th className="text-center">国名</th>
          <th className="text-center">当該外国の個人情報保護制度</th>
          <th className="text-center">第三者が講ずる個人情報保護措置</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>アメリカ合衆国</td>
          <td>
            個人情報保護委員会が提供する情報を以下のリンクからご確認ください。<br />
            https://www.ppc.go.jp/files/pdf/USA_report.pdf
          </td>
          <td>提供先の第三者は、OECDプライバシーガイドライン8原則に対応する措置を講じております。</td>
        </tr>
        <tr>
          <td>シンガポール</td>
          <td>
            個人情報保護委員会が提供する情報を以下のリンクからご確認ください。 <br />
            https://www.ppc.go.jp/files/pdf/singapore_report.pdf
          </td>
          <td>提供先の第三者は、OECDプライバシーガイドライン8原則に対応する措置を講じております。</td>
        </tr>
      </tbody>
    </table>

    <h2 className="h4 mb-4">5.個人情報の管理</h2>
    <ol>
      <li>内容の正確性の確保<br />
        当社は、個人情報につき、「2．個人情報の利用目的」の達成に必要な範囲内において、正確かつ最新の内容に保つとともに、利用する必要がなくなったときは当該個人情報を消去するよう努めます。
      </li>
      <li>安全管理措置<br />
        当社は、個人情報の漏えい、滅失又は毀損の防止その他の安全管理のために必要かつ適切な措置を行います。当社における安全管理措置の内容については、「8.お問い合わせ窓口」までお問い合わせください。
      </li>
      <li>従業者の監督<br />
        当社は、個人情報を従業者に取り扱わせるにあたっては、個人情報の適正な取扱いを周知徹底するとともに適正な教育を行い、必要かつ適正な監督を行います。
      </li>
      <li>委託先の監督<br />
        当社は、個人情報の取扱いを委託する場合には、委託先には適切な安全管理措置を講じている協力会社を選定し、委託先に対し必要かつ適切な監督を行います。
      </li>
    </ol>

    <h2 className="h4 mb-4">6.Cookieその他の技術の利用</h2>
    <ol>
      <li>当社は、本サービスの提供に際して、Cookie及びこれに類する技術を利用することがあります。当社は、利用者のCookie情報、利用者が閲覧した広告及びウェブページ並びに利用者の利用環境等の情報を自動的に利用者のブラウザから取得し、サーバに記録することで、本サービスの利用状況等を把握し、本サービスの品質を向上させるためにこれらの情報を用いることがあります。</li>
      <li>Cookieを無効化したい利用者は、ウェブブラウザの設定を変更することによりCookieを無効化することができます。ただし、Cookieを無効化すると、本サービスを正常に利用できない場合があります。</li>
      <li>当社は、第1項に定めるほか、Google LLCが提供するサービス「Googleアナリティクス」を利用して、本サービスの利用に際して利用者が利用する通信端末に関する情報（通信端末の個体識別番号、IPアドレス、位置情報、Cookie情報、利用ログ等）を取得し、利用者に関する情報の解析並びにその結果を用いた本サービスの利用状況の計測、分析及び本サービスの改善を目的として、これらの情報を利用することがあります。Google analyticsの詳細及びオプトアウト手続については、「Google アナリティクス利用規約」及び「Google のサービスを使用するサイトやアプリから収集した情報の Google による使用」をご確認ください。</li>
    </ol>

    <h2 className="h4 mb-4">7.保有個人データに関する受付</h2>
    <p>当社は利用者からの個人情報保護法に基づく保有個人データの利用目的の通知、保有個人データ若しくは第三者提供記録の開示、保有個人データの訂正・追加・削除又は保有個人データの利用停止・第三者提供の停止のご請求に適切に対応いたします。手続等については、「8.お問い合わせ窓口」までお問い合わせください。</p>

    <h2 className="h4 mb-4">8.お問い合わせ窓口</h2>
    <p>本サービスの個人情報の取扱いに関するご質問、ご照会、苦情等は、以下の窓口までご連絡ください。<br />
      〒105-6490 <br />
      東京都港区虎ノ門1丁目17番1号  虎ノ門ヒルズビジネスタワー15階<br />
      ドーナッツロボティクス株式会社<br />
      メール：info@donutrobotics.com<br /><br />


      2023年8月31日制定<br /><br />

      東京都港区虎ノ門1丁目17番1号<br />
      虎ノ門ヒルズビジネスタワー15階<br />
      ドーナッツロボティクス株式会社<br />
      代表取締役　小野泰助
    </p>



    <h1 className="h3 mb-4 text-center mt-5">EU Customers Privacy Policy</h1>

    <p>This EU Customers Privacy Policy shall be read and construed in conjunction with the other provisions of our Privacy Policy. Donut Robotics Co., Ltd. ("We", " us", or " our") process any personal data of Customers in the European Union, Iceland, Lichtenstein and Norway in accordance with the General Data Protection Regulation (Regulation (EU) 2016/679 of 27 April 2016) (hereinafter referred to as " GDPR"). For the purposes of this EU Customers Privacy Policy, the terms defined under the GDPR have the meanings ascribed to them under the GDPR.</p>


    <h2 className="h4 mb-4">1. Processing purposes and legal justification of the processing</h2>
    <p>We generally use the following legal justifications: (i) You have given your consent to the processing (Article 6(1)(a) GDPR; "Consent Justification"), and (ii) the processing is necessary for (a) the performance of a contract to which the data subject is party (Article 6(1)(b) GDPR; "Contract Justification"), (b) compliance with a legal obligation (Article 6(1)(c) GDPR; "Legal Obligation Justification") and (c) realizing a legitimate interest (Article 6(1)(f) GDPR; "Legitimate Interest Justification").</p>
    <p>We process your personal data for the purposes and on the legal basis described below.</p>
    <table className="table">
      <thead>
        <tr>
          <th className="text-center">Purposes of processing</th>
          <th className="text-center">Legal basis of processing</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Article 2 (1), (2), (3), (5) and (11) of the Privacy Policy
            Article 4 (1) of the Privacy Policy</td>
          <td>Contract Justification</td>
        </tr>
        <tr>
          <td>Article 2 (4) of the Privacy Policy
            Article 4 (2) and (3) of the Privacy Policy</td>
          <td>Legitimate Interest Justification. Our legitimate interest is to detect and prevent any possible fraud.</td>
        </tr>
        <tr>
          <td>Article 2 (6), (7), (8), (9) and (10) of the Privacy Policy
            Article 4 (4), (5), (6) and (7) of the Privacy Policy</td>
          <td>Consent Justification</td>
        </tr>
      </tbody>
    </table>


    <h2 className="h4 mb-4">2. Disclosure of personal data</h2>
    <ol>
      <li>Your personal data will be processed by our duly authorized personnel in accordance with the GDPR. We may engage third parties as processors on our behalf and disclose your personal data to them. Those third parties include service providers that convert voice data into text, as well as third parties as stated in Article 4 (1), (5), (6) and (7) of the Privacy Policy.</li>
      <li>We may also disclose your personal data (i) where required by the applicable laws or regulations, (ii) in connection with corporate activities such as mergers, and (iii) when defending ourselves in an administrative or judicial proceeding.</li>
    </ol>


    <h2 className="h4 mb-4">3. Transfer to third countries outside the EEA</h2>
    <p>Your personal data may be transferred to third countries outside the European Economic Area with or without an adequate decision by the European Commission. In the case of any transfer of personal data to a country that does not have an adequate level of data protection in light of the standards under the GDPR, we will ensure such transfer will be made through the Standard Contractual Clauses adopted in accordance with the GDPR.</p>


    <h2 className="h4 mb-4">4. Retention periods for and deletion of Your Data</h2>
    <ol>
      <li>Your personal data processed for the purposes hereunder will be stored only to the extent necessary. If a judicial action is initiated, your personal data may be stored until the end of such action, including any potential periods for appeal, and will then be deleted or archived as permitted by applicable law.</li>
      <li>In principle, we will retain your personal data only as long as required or permitted by applicable law. Afterwards, we will remove your personal data from our systems and records and/or take steps to properly anonymize it so that you can no longer be identified from it.</li>
    </ol>

    <h2 className="h4 mb-4">5. Your statutory rights</h2>
    <p>Under the conditions set out under applicable law (i.e., the GDPR), you have the following rights:</p>
    <ol>
      <li>Right of access: You have the right to obtain confirmation from us as to whether or not personal data concerning you is being processed, and, where that is the case, to request access to said personal data. The access information includes – inter alia – the purposes of the processing, the categories of personal data concerned, and the recipients or categories of recipients to whom the personal data have been or will be disclosed. You have the right to obtain a copy of the personal data undergoing processing. For additional copies requested by you, we may charge a reasonable fee based on administrative costs.</li>
      <li>Right to rectification: You have the right to obtain from us the rectification of inaccurate personal data concerning you. Depending on the purposes of the processing, you have the right to have incomplete personal data completed, including by means of providing a supplementary statement.</li>
      <li>Right to erasure (right to be forgotten): You have the right to ask us to erase your personal data.</li>
      <li>Right to restriction of processing: You have the right to request the restriction of processing your personal data. In this case, the respective data will be marked and may only be processed by us for certain purposes.</li>
      <li>Right to data portability: You have the right to receive the personal data concerning you which you have provided to us in a structured, commonly used and machine-readable format and you have the right to transmit said personal data to another entity without hindrance from us.</li>
      <li>Right to withdraw your consent: If you have given your consent regarding certain types of processing activities, you can withdraw this consent at any time with future effect. Such a withdrawal will not affect the lawfulness of the processing prior to the consent withdrawal.</li>
      <li>Right to object:
        <p className="border border-secondary p-2">You have the right to object at any time, on grounds relating to your particular situation, to the processing of your personal data by us and we can be required to no longer process your personal data. If you have a right to object and you exercise this right, your personal data will no longer be processed for such purposes by us. Exercising this right will not incur any costs. Such a right to object may not exist if the processing of your personal data is necessary to take steps prior to entering into a contract or to perform a contract already concluded.</p>
        <p>Please note that the aforementioned rights might be limited under applicable national data protection law. We remain the universal point of contact for your execution of these rights.You also have the right to lodge a complaint with the competent supervisory authority in the member state of your habitual residence or in the member state wherein the alleged infringement of the GDPR took place.</p>
      </li>
    </ol>

    <h2 className="h4 mb-4">6. No obligation to provide your personal data</h2>
    <p>You have no obligation to provide your personal data to us either under applicable law or a contract. Once you make an order for the Service, we may require your personal data based on the contract with you, in which case your failure to provide personal data we require may prevent us from providing you with the Service.</p>

    <h2 className="h4 mb-4">7. Identity and contact details of the controller</h2>
    <p>When you interact and provide your personal data with us, we are the controller of your personal data who determine the purposes and means of processing your personal data. You can contact us by visiting our principal place of business at Toranomon Hills Business Tower 15F, 17-1, Toranomon 1-chome, Minato-ku, Tokyo 105-6490, Japan or sending an email to the following email address : info@donutrobotics.com.<br /><br />
      EU Customers Privacy Policy last updated: 31 August, 2023
    </p>



    <h1 className="h3 mb-4 text-center mt-5">利用者に関する情報の外部送信</h1>

    <p>当社は、アクセス解析による当社サービスの改善等の目的のために、タグや情報収集モジュール等のツールを利用して、お客様に関する情報を収集し、外部に送信しています。ツール提供者の名称、ツール提供者に送信されるお客様に関する情報の内容、送信される情報の利用目的等の詳細については、以下をご確認ください。<br />
      なお、お客様に関する情報の送信先となるツール提供者は、下記の目的及び当社に対するサービスの提供の目的のほか、ツール提供者のプライバシーポリシー等に基づきお客様の情報を利用する場合があります。</p>
    <table className="table">
      <thead>
        <tr>
          <th className="text-center">サービス名</th>
          <th className="text-center">送信先事業者名</th>
          <th className="text-center">送信される利用者の情報</th>
          <th className="text-center">当社における利用目的</th>
          <th className="text-center">送信先事業者における利用目的</th>
          <th className="text-center">オプトアウト／その他</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Googleアナリティクス</td>
          <td>Google LLC及びその関係会社</td>
          <td>・インターネット通信に通常用いられるシステム、デバイス、ネットワーク、通信に関する情報<br />
            ・位置情報<br />
            ・サイト・アプリ上での行動に関するデータ<br />
            ・閲覧ページに関するデータ<br />
            ・ユーザー識別子（cookie、端末識別子等）</td>
          <td>閲覧の傾向や履歴の分析のため</td>
          <td><a href="https://policies.google.com/privacy?hl=ja">詳細</a></td>
          <td><a href="https://tools.google.com/dlpage/gaoptout?hl=ja">詳細</a></td>
        </tr>
        <tr>
          <td>Web Speech API</td>
          <td>orld Wide Web Consortium</td>
          <td>・API を使用する Web サイトのドメイン、デフォルトのブラウザ言語、Web サイトの言語設定
            ・音声データ</td>
          <td>取得した音声データをテキストに変換するため</td>
          <td>音声データをテキストに変換するため</td>
          <td><a href="https://wicg.github.io/speech-api/">詳細</a></td>
        </tr>
        <tr>
          <td>Google Speech to Text API</td>
          <td>Google LLC及びその関係会社</td>
          <td>・音声データ
            ・音声データを文字変換した情報</td>
          <td>取得した音声データをテキストに変換するため</td>
          <td>音声データをテキストに変換するため</td>
          <td><a href="https://cloud.google.com/terms">詳細</a></td>
        </tr>
        <tr>
          <td>AmiVoice API</td>
          <td>株式会社アドバンスト・メディア</td>
          <td>・音声データ
            ・音声データを文字変換した情報</td>
          <td>取得した音声データをテキストに変換するため</td>
          <td>音声データをテキストに変換するため</td>
          <td><a href="https://acp.amivoice.com/amivoice_api/terms/">詳細</a></td>
        </tr>
        <tr>
          <td>Azure Speech to Text API</td>
          <td>Microsoft Corporation</td>
          <td>・音声データ
            ・音声データを文字変換した情報</td>
          <td>取得した音声データをテキストに変換するため</td>
          <td>音声データをテキストに変換するため</td>
          <td><a href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/code-of-conduct">詳細</a></td>
        </tr>
      </tbody>
    </table>
  </div>
)});
