import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import UserInfo from "../../store/UserInfo"
import { QuestionsBody } from './QuestionsBody';

export const Questions = observer(() => {
  const { t } = useTranslation()

  return <div className='overflow-y-auto text-donut p-4'>
    { UserInfo.loggedIn
    ? (
      null
    )
    : (
      <Link to="/" className="mx-2 link-secondary">
        {t('戻る')}
      </Link>
    )}

    <h1 className="my-4">{t('よくある質問')}</h1>
    <QuestionsBody/>
  </div>
})
