type ClassNameParams = (
  string
  | string[]
  | { [key: string]: boolean }
)

/**
 * Returns ClassName Format for React.
 *
 * behaves similarly to a library called className, albeit
 * it's a stripped off version of original.
 *
 * It's easier to implement sane enough basic function here
 * instead of adding yet another dependency.
 *
 * @example
 * ```ts
 * const cNameA = className("btn", "btn-primary")
 * console.log(cNameA) // returns "btn btn-primary"
 *
 * const cNameB = className(["btn", "btn-primary"], "btn-secondary")
 * console.log(cNameB) // returns "btn btn-primary btn-secondary"
 *
 * const isHovered = true
 * const isClicked = false
 *
 * const cNameC = className({
 *   btn: true,
 *   "btn-primary": isHovered,
 *   "btn-highlight": isClicked,
 * })
 *
 * console.log(cNameC) // returns "btn btn-primary", "btn-highlight" is ignored
 * ```
 */
export function className(...classNames: ClassNameParams[]): string {
  const result: string[] = []

  for (const cName of classNames) {
    if (typeof cName === 'string') {
      result.push(cName.trim())
    } else if (Array.isArray(cName)) {
      const innerResult = className(...cName)
      result.push(innerResult)
    } else if (typeof cName === "object") {
      for (const [cNameInner, isActive] of Object.entries(cName)) {
        if (isActive) {
          result.push(cNameInner.trim())
        }
      }
    }
  }

  return result.join(' ')
}

