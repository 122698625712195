export type Event<T, D, R> = {
  action: string;
  targets?: T;
  data?: D;
  callback?: (result: R[]) => void;
}

export type ObjectChangeListener<T, D, R> = (event: Event<T, D, R>) => R

class EventDispatcher {
  static DEFAULT_CHANNEL = 'evt_default'
  private listeners: { [channel: string]: ObjectChangeListener<any, any, any>[] } = {}

  countListener = (channel = EventDispatcher.DEFAULT_CHANNEL) => {
    return this.listeners[channel] ? this.listeners[channel].length : 0
  }

  /**
   * @return function to remove listener
   */
  addListener = <T, D, R>(listener: ObjectChangeListener<T, D, R>, channel = EventDispatcher.DEFAULT_CHANNEL) => {
    if (!this.listeners[channel]) {
      this.listeners[channel] = []
    }
    this.listeners[channel].push(listener)

    return () => {
      this.removeListener(listener, channel)
    }
  }

  removeListener = (listener: ObjectChangeListener<any, any, any>, channel = EventDispatcher.DEFAULT_CHANNEL) => {
    if (this.listeners[channel]) {
      const index = this.listeners[channel].indexOf(listener)
      if (index >= 0) {
        this.listeners[channel].splice(index, 1)
      }
    }
  }

  /**
   * Dispatch an event to all registered listeners.
   */
  dispatch = <R>(event: Event<any, any, R>, channel = EventDispatcher.DEFAULT_CHANNEL) => {
    let handled = false
    if (this.listeners[channel]) {
      const { callback } = event
      // do not pass callback to listeners
      delete event.callback
      if (!callback) {
        // simple event without callback
        this.listeners[channel].forEach(listener => {
          const passed = listener(event)
          handled = handled || !passed
        })
      } else {
        // event with callback
        handled = true // by default return true in this case
        const promises = this.listeners[channel].map(listener => new Promise<R>((resolve) => {
          (async () => {
            const res = await listener(event)
            resolve(res)
          })()
        }))
        Promise.all(promises).then((results) => {
          callback(results.filter(res => res !== undefined))
        })
      }
    }
    return handled
  }

  notify = (action: string, channel = EventDispatcher.DEFAULT_CHANNEL) => {
    return this.dispatch({ action }, channel)
  }
}

EventDispatcher.DEFAULT_CHANNEL = 'evt_default'

export default EventDispatcher
