import { useTranslation } from 'react-i18next'
import { type LegacyRef } from 'react'

import { SIGN_IN_MODE } from './constants'

type InputFieldProps = {
  id: string
  isCompany: boolean
  mode: string
  label: string
  inputRef: LegacyRef<HTMLInputElement>
  isOptional?: boolean
}

const InputField = ({ id, isCompany, mode, label, inputRef, isOptional }: InputFieldProps) => {
  const { t } = useTranslation()
  return (
    <div className='full-width' style={{ display: isCompany ? 'block' : 'none' }}>
      <div className='form-group mt-4 mb-2 full-width' style={{ display: mode !== SIGN_IN_MODE.REGISTER_PLAN_SELECT ? 'block' : 'none' }}>
        <label htmlFor={id} className='text-primary ms-3'>
          <b>{t(label)}</b>
          {isOptional && <small className='text-secondary ms-2'>{t('（任意）')}</small>}
        </label>
        <input className='form-control' id={id} ref={inputRef} placeholder={t(label)} />
      </div>
    </div>
  )
}

type CompanyFieldsProps = {
  isCompany: boolean
  mode: string
  nameRef: LegacyRef<HTMLInputElement>
  nameKanaRef: LegacyRef<HTMLInputElement>
  companyRef: LegacyRef<HTMLInputElement>
  companyKanaRef: LegacyRef<HTMLInputElement>
  deptRef: LegacyRef<HTMLInputElement>
  phoneRef: LegacyRef<HTMLInputElement>
  addrRef: LegacyRef<HTMLInputElement>
  resellerCodeRef: LegacyRef<HTMLInputElement>
  resellerNameRef: LegacyRef<HTMLInputElement>
  userCodeRef: LegacyRef<HTMLInputElement>
}

export const CompanyFields = ({
  isCompany,
  mode,
  nameRef,
  nameKanaRef,
  companyRef,
  companyKanaRef,
  deptRef,
  phoneRef,
  addrRef,
  resellerCodeRef,
  resellerNameRef,
  userCodeRef,
}: CompanyFieldsProps) => {
  return (
    <>
      <InputField id='signin-name' label='お名前' mode={mode} isCompany={isCompany} inputRef={nameRef} />
      <InputField id='signin-name-kana' label='お名前（フリガナ）' mode={mode} isCompany={isCompany} inputRef={nameKanaRef} />

      <InputField id='signin-company' label='会社名' mode={mode} isCompany={isCompany} inputRef={companyRef} />
      <InputField id='signin-company-kana' label='会社名（フリガナ）' mode={mode} isCompany={isCompany} inputRef={companyKanaRef} />

      <InputField id='signin-dept' label='部署名' mode={mode} isCompany={isCompany} inputRef={deptRef} isOptional />

      <InputField id='signin-phone' label='電話番号' mode={mode} isCompany={isCompany} inputRef={phoneRef} />
      <InputField id='signin-addr' label='住所' mode={mode} isCompany={isCompany} inputRef={addrRef} />

      <InputField id='signin-reseller-code' label='販売店コード' mode={mode} isCompany={isCompany} inputRef={resellerCodeRef} isOptional />
      <InputField id='signin-reseller' label='販売店名' mode={mode} isCompany={isCompany} inputRef={resellerNameRef} isOptional />
      <InputField id='signin-usercode' label='ユーザーコード' mode={mode} isCompany={isCompany} inputRef={userCodeRef} isOptional />
    </>
  )
}
