import {
  type CollectionReference,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  type WhereFilterOp,
  query,
  type Query,
  type DocumentSnapshot,
  limit,
  orderBy,
  type OrderByDirection,
  startAfter,
  type QueryDocumentSnapshot,
  type Unsubscribe,
} from 'firebase/firestore'
import { makeAutoObservable, observable } from 'mobx'

import { firestore } from '../firebase'

export interface IDataObj {
  id: string
  setData: (data: any) => void
}

type WhereInput = [
  field: string,
  value: any,
  operator?: WhereFilterOp,
]

type OnSnapshotCallback = (snapshot: DocumentSnapshot) => void

class CollectionStore<T extends IDataObj> {
  loaded = false
  data = observable.map<string, T>()
  dataArray: T[] = []
  private collectionName: string
  private dbRef: CollectionReference
  private needTimestamps: boolean

  onBeforeCreate?: (id: string, data: any) => any
  createObject: (id: string, data: any) => T

  constructor(collectionName: string, ModelClass: new (...args: any[]) => T, needTimestamps = false) {
    this.collectionName = collectionName
    this.needTimestamps = needTimestamps

    // create base collection ref
    this.dbRef = collection(firestore, collectionName)
    this.createObject = (id: string, data: any) => {
      data = this.onBeforeCreate ? this.onBeforeCreate(id, data) : data
      return new ModelClass(id, data)
    }

    makeAutoObservable(this)
  }

  filter = (ref: Query): Query => {
    return ref
  }

  getDbRef = () => {
    return this.dbRef
  }

  getQuery = () => {
    return this.filter(this.dbRef)
  }

  private cancelOnSnapshot: undefined | (() => void) = undefined
  startSync = () => {
    if (this.cancelOnSnapshot) {
      // already syncing
      return
    }

    console.log(`[${this.collectionName}] start syncing`)
    this.loaded = false
    this.data.clear()
    this.dataArray = []

    // start syncing
    this.cancelOnSnapshot = onSnapshot(this.filter(this.dbRef), (snapshot) => {
      this.setLoaded(true)
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'removed') {
          this.deleteDataLocal(change.doc.id, change.oldIndex)
        }
        if (change.type === 'added') {
          this.setDataLocal(change.doc.id, change.doc.data(), change.newIndex)
        }
        if (change.type === 'modified') {
          this.updateDataLocal(change.doc.id, change.doc.data())
        }
      })
    })
  }

  stopSync = () => {
    if (this.cancelOnSnapshot) {
      console.log(`[${this.collectionName}] stop syncing`)

      this.cancelOnSnapshot()
      this.cancelOnSnapshot = undefined
    }
  }

  /* Actions to modify local store */

  setDataLocal = (id: string, data: any, index?: number) => {
    const newObj = this.createObject(id, data)
    this.data.set(id, newObj)
    if (index === undefined) {
      this.dataArray.push(newObj)
    } else {
      this.dataArray.splice(index, 0, newObj)
    }
  }

  updateDataLocal = (id: string, data: any) => {
    const item = this.data.get(id)
    item?.setData(data)
  }

  deleteDataLocal = (id: string, index?: number) => {
    this.data.delete(id)
    if (index === undefined) {
      // find obj manually
      index = -1
      for (let i = 0; i < this.dataArray.length; i++) {
        if (this.dataArray[i].id === id) {
          index = i
          break
        }
      }
    }

    if (index >= 0) {
      this.dataArray.splice(index, 1)
    }
  }

  /* Utility methods for modifying collection on Firestore */

  create = (data: T) => {
    if (this.needTimestamps) {
      data = {
        ...data,
        createdAt: serverTimestamp(),
      }
    }
    return addDoc(this.dbRef, data)
  }

  createOrReplace = (id: string, data: any) => {
    if (this.needTimestamps) {
      data = {
        ...data,
        updatedAt: serverTimestamp(),
      }
    }
    return setDoc(doc(this.dbRef, id), data)
  }

  update = (id: string, data: any) => {
    if (this.needTimestamps) {
      data = {
        ...data,
        updatedAt: serverTimestamp(),
      }
    }
    return updateDoc(doc(this.dbRef, id), data)
  }

  delete = (id: string) => {
    return deleteDoc(doc(this.dbRef, id))
  }

  /* Utility methods for querying data on Firestore */

  getAll = () => {
    return getDocs(this.filter(this.dbRef))
  }

  findById = (id: string) => {
    return getDoc(doc(this.dbRef, id))
  }

  findBy = async (...filters: WhereInput[]) => {
    try {
      let ref = this.filter(this.dbRef)
      let q: Query | undefined = undefined
      // apply all input filters
      filters.forEach((/** @type {WhereInput} */whereInput) => {
        q = query(ref, where(whereInput[0], whereInput[2] || '==', whereInput[1]))
        // ref.where(whereInput[0], whereInput[2] || '==', whereInput[1])
      })
      const querySnapshot = await getDocs(q || ref)
      const res: T[] = []

      querySnapshot.forEach((doc) => {
        res.push(this.createObject(doc.id, doc.data()))
      })

      return res
    } catch (e) {
      console.warn(e)
      return []
    }
  }

  /**
   * Start monitoring a specific document
   */
  monitor = (uid: string, onChange: OnSnapshotCallback) => {
    return onSnapshot(doc(this.dbRef, uid), onChange)
  }

  /* Other actions */

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded
  }
}

export default CollectionStore

type OnNewDataCallback<T> = (items: (T | undefined)[]) => void

export class Pagination<T extends IDataObj> {
  itemPerPage = 10
  loading = false
  hasPrev = false
  hasNext = true
  lastReached = false
  itemCount = 0
  maxItemCount = 0

  private store: CollectionStore<T>
  private orderByField: string
  private order: OrderByDirection
  private firstDocs: QueryDocumentSnapshot[] = []
  private lastDocs: QueryDocumentSnapshot[] = []
  private docCounts: number[] = []
  private cancelSnap?: Unsubscribe

  /**
   * store current loaded items
   */
  items: T[] = []
  onNewData?: OnNewDataCallback<T>

  constructor(store: CollectionStore<T>, orderByField: string, order: OrderByDirection = 'asc') {
    this.store = store
    this.orderByField = orderByField
    this.order = order

    makeAutoObservable(this)
  }

  setItemPerPage = (items: number) => {
    if (items !== this.itemPerPage) {
      this.itemPerPage = items

      // reset data
      const loaded = this.itemCount > 0
      this.reset()
      if (loaded) {
        this.getNext()
      }
    }
  }

  reset = (next = false) => {
    this.loading = false
    this.hasPrev = false
    this.hasNext = true
    this.lastReached = false
    this.itemCount = 0
    this.maxItemCount = 0
    this.items = []
    this.firstDocs = []
    this.lastDocs = []
    this.docCounts = []

    if (next) {
      this.getNext()
    }
  }

  setData = (items: T[], currentPage: number) => {
    if (items.length) {
      // compute current item count
      this.itemCount = this.docCounts.reduce((count, docs, i) => count + (i <= currentPage ? docs : 0), 0)
      this.hasNext = currentPage < this.docCounts.length - 1 || items.length >= this.itemPerPage
      if (!this.hasNext) {
        this.lastReached = true
      }
      if (this.maxItemCount < this.itemCount) {
        this.maxItemCount = this.itemCount
      }
      this.hasPrev = this.itemPerPage < this.itemCount

      this.items = items
      this.loading = false

      this.onNewData && this.onNewData(this.items)
    } else {
      // edge case, no more item to load
      this.hasNext = false
      this.lastReached = true
      this.loading = false
    }
  }

  updateDataLocal = (id: string, data: any) => {
    this.items.forEach((item) => {
      if (item.id === id) {
        item.setData && item.setData(data)
      }
    })
  }

  getNext = async (page = 0) => {
    if ((page <= 0 && !this.hasNext) || page > this.docCounts.length) {
      return
    }
    this.loading = true

    // load next batch of data
    let nextQuery = query(this.store.getQuery(), orderBy(this.orderByField, this.order), limit(this.itemPerPage))

    const currentPage = !page ? Math.ceil(this.itemCount / this.itemPerPage) : Math.min(page - 1, Math.ceil(this.maxItemCount / this.itemPerPage))
    if (currentPage > 0) {
      const lastDocument = this.lastDocs[currentPage - 1]
      nextQuery = query(nextQuery, startAfter(lastDocument))
    }

    // const docSnapshots = await getDocs(nextQuery)

    this.cancelSnap && this.cancelSnap()
    this.cancelSnap = onSnapshot(nextQuery, (snapShot) => {
      const newData = [...this.items]

      snapShot.docChanges().forEach((docSnap) => {
        switch (docSnap.type) {
          case 'removed':
            newData.splice(docSnap.oldIndex, 1)
            break
          case 'added':
            newData.splice(docSnap.newIndex, 0, this.store.createObject(docSnap.doc.id, docSnap.doc.data()))
            break
          case 'modified':
            newData[docSnap.newIndex].setData(docSnap.doc.data())
            break
        }
      })

      // store the first and the last visible document
      if (newData.length) {
        if (currentPage === this.firstDocs.length) {
          this.firstDocs.push(snapShot.docs[0])
          this.lastDocs.push(snapShot.docs[snapShot.docs.length - 1])
          this.docCounts.push(newData.length)
        }
      }

      // update data
      this.setData(newData, currentPage)
    })

    // extract result
    // const items: T[] = []
    // docSnapshots.forEach((doc) => {
    //   items.push(this.store.createObject(doc.id, doc.data()))
    // })

    // // store the first and the last visible document
    // if (items.length) {
    //   if (currentPage === this.firstDocs.length) {
    //     this.firstDocs.push(docSnapshots.docs[0])
    //     this.lastDocs.push(docSnapshots.docs[docSnapshots.docs.length - 1])
    //     this.docCounts.push(items.length)
    //   }
    // }

    // // update data
    // this.setData(items, currentPage)
  }

  getPrev = async () => {
    if (!this.hasPrev) {
      return
    }

    const currentPage = Math.ceil(this.itemCount / this.itemPerPage)
    this.getNext(currentPage - 1)
  }

  setOrder = (orderByField: string, order: OrderByDirection) => {
    this.orderByField = orderByField
    if (order) {
      this.order = order
    }

    this.reset()
  }
}
