import { useTranslation } from 'react-i18next'

import UserInfo, { TPlan } from '../../../store/UserInfo'
import TimeCounter from '../../../view/TimeCounter'

export const PlanItem = ({ p, children }: { p?: TPlan, children: React.ReactNode }) => {
  const { t } = useTranslation()
  if (p == null) {
    return (
      <div className='card'>
        <div className='card-body'>
          <h5 className='card-title'>
            <i className='text-donut'>{t('未選択')}</i>
          </h5>
          <p className='card-text'>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={'card'}>
      <div className='card-body'>
        <h5 className='card-title'>{p?.name ?? ''}
        </h5>
        <h6 className='text-donut subtitle'>
          <img height='25px' src={`/planicons/${p?.productCode}.png`}
            alt={`Plan Icon for ${p?.name}`}
            onError={elem => {
              elem.currentTarget.onerror = null
              elem.currentTarget.src = '/planicons/EMPTY.png'
            }}
          />
        </h6>
        <div className='card-text mb-3'>
          {UserInfo.isUnlimitedPlan(p) ? (
            <span className='text-danger'>{t('時間無制限・メンバー数', { count: p.maxUseTime })}</span>
          ) : (
            <TimeCounter second={p?.maxUseTime ?? 0} prefix={t('毎月 ')} showTime />
          )}
        </div>
        <p className='card-text'>
          {t('currency.yen', { count: p.amountJpy })} / {p.isMonthly ? t('月') : t('年')}
        </p>
        {children}
      </div>
    </div>
  )
}
