import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import UserInfo from "../../store/UserInfo"
import { PolicyBody } from "./PolicyBody"

export const Policies = observer(() => {
    const { t } = useTranslation()

    return <div className="overflow-y-auto text-donut p-4">
        {UserInfo.loggedIn ? (
            null
        ) : (
            <Link to="/" className="mx-2 link-secondary">
                {t('戻る')}
            </Link>
        )}
        <h3 className="mb-4 text-center">{t('利用規約')}</h3>

        <PolicyBody />
    </div>
})
