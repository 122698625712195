import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
// import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
}

const app = initializeApp(firebaseConfig)

export const firestore = getFirestore(app)
if (process.env.REACT_APP_FIREBASE_CONNECT_EMULATOR_HOST && process.env.REACT_APP_FIREBASE_CONNECT_EMULATOR_PORT) {
  const host = process.env.REACT_APP_FIREBASE_CONNECT_EMULATOR_HOST
  const port = process.env.REACT_APP_FIREBASE_CONNECT_EMULATOR_PORT
  console.log(`Connecting to ${host}:${port}`)
  connectFirestoreEmulator(firestore, host, parseInt(port))
}

// export const functions = getFunctions(app)
