import { type ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import { PolicyBody } from '../policies/PolicyBody'

export const EmbeddedTos = ({ agreed, onChange }: { agreed: boolean, onChange: (agreed: boolean) => void }) => {
  const { t } = useTranslation()

  const handleCheckedChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.checked)
  }

  return (
    <>
      <p className='mt-3'>
        <b>{t('利用規約をお読みください')}</b>
      </p>
      <div className='bg-white px-3 py-2 border text-donut' style={{ width: '100%', height: '10em', overflowY: 'auto', fontSize: '0.8em' }}>
        <PolicyBody />
      </div>
      <div className='mt-2'>
        <input id='tos-check' type='checkbox' className='form-check-input' checked={agreed} onChange={handleCheckedChange} />
        <label htmlFor='tos-check' className='form-check-label ms-2'>
          <b>{t('利用規約に同意する')}</b>
        </label>
      </div>
    </>
  )
}
