import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next";

import styles from "./Questions.module.css";

export const QuestionsBody = observer(() => {
  const { t } = useTranslation('questions')

  return (
    <div className={styles.container_of_question}>
        <div className="accordion" id="faqAccordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no1" aria-expanded="true" aria-controls="collapse_no1">
                        Q1. {t('donut AI 議事録はどのようなサービスですか？')}
                    </button>
                </h2>
                <div id="collapse_no1" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A1. {t('donut AI 議事録は、リアルタイムでの会議の文字起こし、翻訳、議事録の自動生成を可能にするサービスです。オンラインミーティングや対面会議の議事録作成を効率化し、より簡単に共有・アーカイブすることが可能になります。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no2" aria-expanded="true" aria-controls="collapse_no2">
                        Q2. {t('利用料金はどのようになっていますか？')}
                    </button>
                </h2>
                <div id="collapse_no2" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A2. {t('利用料金は複数のプランに分かれており、0プラン（無料）、PERSONAL、LITE、BASIC、STANDARD、PRO、ENTERPRISEがあります。詳細は料金表をご覧ください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no3" aria-expanded="true" aria-controls="collapse_no3">
                        Q3. {t('プランの変更は可能ですか？')}
                    </button>
                </h2>
                <div id="collapse_no3" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A3. {t('はい、プランの変更は可能です。お申込みの日付から変更後のプランが適用され翌月利用分より変更後のプラン金額にて請求処理されます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no4" aria-expanded="true" aria-controls="collapse_no4">
                        Q4. {t('契約期間はどのようになっていますか？')}
                    </button>
                </h2>
                <div id="collapse_no4" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A4. {t('本サービスの契約期間は1か月とし、特に解約等の手続きを行わない限り、自動的に同一条件で更新されます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no5" aria-expanded="true" aria-controls="collapse_no5">
                        Q5. {t('サービスの解約はどうすればいいですか？')}
                    </button>
                </h2>
                <div id="collapse_no5" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A5. {t('有料プランの解約（0プランへの変更含む）は、有料プラン解約フォームより行うことが出来ます。解約後においても有料プランの有効期間は、有料プランをご利用いただけます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no6" aria-expanded="true" aria-controls="collapse_no6">
                        Q6. {t('サービスを完全に終了（契約事業者登録の抹消）したい場合はどうすればいいですか？')}
                    </button>
                </h2>
                <div id="collapse_no6" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A6. {t('契約事業者登録の抹消を希望する場合は、アカウント解約フォームよりお申込みください。通知を踏まえた処理の完了日から30日後に契約事業者登録の抹消の効果が発生します。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no7" aria-expanded="true" aria-controls="collapse_no7">
                        Q7: {t('donut AI 議事録のセキュリティ対策はどのようになっていますか？')}
                    </button>
                </h2>
                <div id="collapse_no7" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A7: {t('donut AI 議事録は、ユーザーのデータ保護とセキュリティを最優先事項としています。アクセス管理やセキュリティポリシーに基づいて運用されています。具体的なセキュリティ対策については、公式ウェブサイトまたはカスタマーサポートにお問い合わせください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no8" aria-expanded="true" aria-controls="collapse_no8">
                        Q8: {t('サポート体制について教えてください。')}
                    </button>
                </h2>
                <div id="collapse_no8" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A8: {t('donut AI 議事録では、技術的な問題や利用方法に関する疑問に対して、専任のサポートチームが対応しています。サポートが必要な場合は、メールや専用フォームからお問い合わせいただけます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no9" aria-expanded="true" aria-controls="collapse_no9">
                        Q9. {t('donut AI 議事録で対応している言語は何ですか？')}
                    </button>
                </h2>
                <div id="collapse_no9" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A9. {t('donut AI 議事録は、最大99言語での会議の文字起こしや翻訳に対応しています。具体的な対応言語のリストや、追加される可能性のある新しい言語については、公式ウェブサイトをご確認いただくか、カスタマーサポートにお問い合わせください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no10" aria-expanded="true" aria-controls="collapse_no10">
                        Q10. {t('オフラインでの使用は可能ですか？')}
                    </button>
                </h2>
                <div id="collapse_no10" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A10. {t('donut AI 議事録は、主にオンラインでの使用を前提としていますが、話者を識別する機能を用いて、複数人によるオフライン会議で利用することは可能です。ただし、議事作成能力は、マイク品質に依存しますので、予めご了承ください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no11" aria-expanded="true" aria-controls="collapse_no11">
                        Q11. {t('チームや組織でdonut AI 議事録を利用する場合のメリットは何ですか？')}
                    </button>
                </h2>
                <div id="collapse_no11" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A11. {t('チームや組織での利用を想定して、donut AI 議事録は複数のアカウント管理、集中管理機能などを提供しています。これにより、会議の効率化が可能になります。組織向けのプランやカスタマイズオプションについては、お問い合わせください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no12" aria-expanded="true" aria-controls="collapse_no12">
                        Q12. {t('donut AI 議事録で利用できるデバイスは何ですか？')}
                    </button>
                </h2>
                <div id="collapse_no12" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A12. {t('donut AI 議事録は、PCやタブレット、スマートフォンなど、様々なデバイスで利用可能です。ウェブブラウザを通じてアクセスすることで、どのデバイスからでもサービスを利用することができます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no13" aria-expanded="true" aria-controls="collapse_no13">
                        Q13. {t('会議の文字起こしデータはどのように保存されますか？')}
                    </button>
                </h2>
                <div id="collapse_no13" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A13. {t('会議の文字起こしデータは、セキュリティを確保したクラウドサーバーに保存されます。ユーザーはいつでもアクセスしてデータを確認することができ、必要に応じてダウンロードや共有も可能です。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no14" aria-expanded="true" aria-controls="collapse_no14">
                        Q14. {t('議事録のフォーマットをカスタマイズすることはできますか？')}
                    </button>
                </h2>
                <div id="collapse_no14" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A14. {t('現在準備中です。donut AI 議事録では、出力される議事録のフォーマットをカスタマイズする機能を提供予定です。ユーザーは自身のニーズに合わせて、テンプレートを選択したり、特定のセクションの追加や削除を行うことができます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no15" aria-expanded="true" aria-controls="collapse_no15">
                        Q15. {t('音声認識の精度を向上させる方法はありますか？')}
                    </button>
                </h2>
                <div id="collapse_no15" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A15. {t('音声認識の精度を向上させるために、以下のような方法が推奨されます：')}
                        <ul className="mt-2">
                            <li>{t('背景ノイズを最小限に抑える')}</li>
                            <li>{t('マイクロフォンを使用して、クリアな音声入力を確保する')}</li>
                            <li>{t('話者がマイクロフォンから一定の距離を保つ')}</li>
                            <li>{t('また、donut AI 議事録では、継続的な技術改善により、音声認識の精度を高める努力をしています。')}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no16" aria-expanded="true" aria-controls="collapse_no16">
                        Q16. {t('複数の話者がいる会議でも利用できますか？')}
                    </button>
                </h2>
                <div id="collapse_no16" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A16. {t('はい、donut AI 議事録は複数の話者がいる会議にも対応しています。音声認識技術により、異なる話者の発言を識別し、議事録に適切に反映させることが可能です。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no17" aria-expanded="true" aria-controls="collapse_no17">
                        Q17. {t('donut AI 議事録を教育機関で利用する場合、特別なプランはありますか？')}
                    </button>
                </h2>
                <div id="collapse_no17" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A17. {t('はい、教育機関向けに特別なプランを設けています。これには、割引価格や特別な機能が含まれることがあります。詳細については、教育機関専用のお問い合わせフォームからご連絡ください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no18" aria-expanded="true" aria-controls="collapse_no18">
                        Q18. {t('donut AI 議事録を介して生成される議事録の著作権は誰に帰属しますか？')}
                    </button>
                </h2>
                <div id="collapse_no18" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A18. {t('donut AI 議事録を通じて生成される議事録の著作権は、原則として議事録を生成したアカウントの所有者に帰属します。ただし、特定の契約条件や利用規約により異なる場合がありますので、詳細は利用規約をご確認ください。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no19" aria-expanded="true" aria-controls="collapse_no19">
                        Q19. {t('donut AI 議事録のアカウントを複数人で共有することは可能ですか？')}
                    </button>
                </h2>
                <div id="collapse_no19" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A19. {t('アカウントの共有に関するポリシーは、セキュリティとプライバシーの観点から、推奨しておりません。ただし、組織やチームでの利用を想定したアカウント管理機能を提供しており、管理者が複数のユーザーを管理することができます。')}
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_no20" aria-expanded="true" aria-controls="collapse_no20">
                        Q20. {t('donut AI 議事録の使用中にエラーが発生した場合、どうすればいいですか？')}
                    </button>
                </h2>
                <div id="collapse_no20" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        A20. {t('エラーが発生した場合は、お問い合わせフォームよりサポートチームに連絡して、エラーメッセージや発生状況を詳細に報告してください。')}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})
