import './SttDictionaries.css'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import Papa, { ParseResult } from 'papaparse';
import jschardet from "jschardet";
import Encoding from "encoding-japanese";
import { TargetWord } from "./component/TargetWord";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  createUserSttDictionaries,
  deleteUserSttDictionaries,
  getSystemSttDictionaries,
  getUserSttDictionaries,
  updateUserSttDictionaries,
  SttDictionariesFirebaseData
} from "../../../api/MeetingFirebaseApi";
import UserInfo from "../../../store/UserInfo";

const separatorString: string = ' ';

export const SttDictionaries = observer(() => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState<SttDictionariesFirebaseData[]>([]);

  const defaultRef = useRef<SttDictionariesFirebaseData[]>([]);
  const addTargetWordRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const existSystemSttDictionaries = async (update: string) => {
    const systemSttDictionaries = await getSystemSttDictionaries();
    const systems: Set<string> = new Set();
    for (const systemTargetWord of systemSttDictionaries) {
      systems.add(systemTargetWord.targetWord);
      if (systemTargetWord.sourceWords) {
        systemTargetWord.sourceWords.forEach(word => systems.add(word));
      }
    }
    return systems.has(update);

  }

  const existUserSttDictionaries = async (update: string, updateId?: string) => {
    const userSttDictionaries = await getUserSttDictionaries(UserInfo.id);
    const users: Set<string> = new Set();
    for (const userTargetWord of userSttDictionaries) {
      if (!updateId || updateId !== userTargetWord.id) {
        users.add(userTargetWord.targetWord);
        if (userTargetWord.sourceWords) {
          userTargetWord.sourceWords.forEach(word => users.add(word));
        }
      }
    }
    return users.has(update);
  }

  const checkValidSttDictionaries = async (update: string, updateId?: string) => {
    if(await existSystemSttDictionaries(update)){
      alert(t('管理者によって設定されています。この言葉を使用することはできません。'));
      return false;
    }
    if(await existUserSttDictionaries(update, updateId)){
      alert(t('重複があります。同じ言葉を使用することはできません。'));
      return false;
    }
    return true;
  }

  const onClickUploadButton = () => {
    fileInputRef.current?.click();
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if(!fileInputRef.current || !fileInputRef.current.files || fileInputRef.current.files.length === 0){
      return false;
    }
    const file = fileInputRef.current.files[0];
    if (file.name.split(".").at(-1) !== "csv") {
      alert(t('現在、ファイルアップロード機能はCSVファイル（.csv）のみ対応しています'));
      event.target.value = '';
      return false;
    }
    try {
      const content = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
          try {
            const arrayBuffer = event.target?.result;
            if (!arrayBuffer) {
              return reject("ArrayBuffer is null or undefined");
            }

            const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);

            // Uint8Arrayを文字列に変換する（適切な方法で）
            const binaryString = new TextDecoder("iso-8859-1").decode(uint8Array);

            // 文字コードを判定（文字列形式で渡す）
            const detected = jschardet.detect(binaryString);
            let detectedEncoding = detected?.encoding?.toLowerCase() || "utf-8";
            // windows-1252 を Shift-JIS にフォールバック
            if (detectedEncoding === "windows-1252") {
              detectedEncoding = "shift-jis";
              console.log("Encoding forced to Shift-JIS");
            }
            console.log(`Detected Encoding: ${detectedEncoding}`);

            let content;
            try {
              if (detectedEncoding === "shift-jis") {
                // encoding-japaneseを使ってShift-JISからUTF-8に変換
                const unicodeArray = Encoding.convert(uint8Array, {
                  from: "SJIS",
                  to: "UNICODE",
                  type: "array",
                });
                content = Encoding.codeToString(unicodeArray);
              } else {
                // 他のエンコーディングはTextDecoderを使う
                content = new TextDecoder(detectedEncoding).decode(uint8Array);
              }
            } catch (e) {
              console.warn(`Failed to decode with ${detectedEncoding}, falling back to UTF-8.`);
              content = new TextDecoder("utf-8").decode(uint8Array);
            }
            resolve(content);
          } catch (error) {
            console.error("Error during file reading or conversion:", error);
            reject(error);
          }
        };

        reader.onerror = (error) => {
          console.error("FileReader error:", error);
          reject(error);
        };

        reader.readAsArrayBuffer(file);
      });

      console.log("content", content);
      const parsedData: SttDictionariesFirebaseData[] = await new Promise((resolve, reject) => {
        Papa.parse<string[]>(content, {
          complete: async (result: ParseResult<string[]>) => {
            try {
              const parsedData = result.data.map((row) => ({
                targetWord: row[0], // 1列目を targetWord に
                sourceWords: row.slice(1).filter((word) => word && word.trim() !== '') || undefined, // 2列目以降を sourceWords に
              }));
              resolve(parsedData);
            } catch (error: any) {
              reject(error);
            }
          },
          error: (error: any) => {
            reject(error);
          },
        });
      });
      // 空白などを除外
      const ignoreEmptyRows: SttDictionariesFirebaseData[] = parsedData.filter(p =>
        p.sourceWords && p.sourceWords.length > 0 && p.targetWord !== '');
      const data: SttDictionariesFirebaseData[] = [];
      const errors: string[] = [];
      for (let i = 0; i < ignoreEmptyRows.length; i++) {
        const datum = parsedData[i];
        if (!datum.targetWord || datum.targetWord.trim() === '' || !datum.sourceWords || datum.sourceWords.length === 0) {
          errors.push(`${i + 1}${t('行目')} : ${t('エラーが発生しました。テキストファイルの内容を確認してください。')}`);
          continue;
        }
        const updateStrings = [datum.targetWord, ...datum.sourceWords];
        let hasError = false;
        for (const updateString of updateStrings) {
          if(await existSystemSttDictionaries(updateString)){
            errors.push(`${i + 1}${t('行目')} : ${t('管理者によって設定されています。この言葉を使用することはできません。')}`);
            hasError = true;
            continue;
          }
          if(await existUserSttDictionaries(updateString)){
            errors.push(`${i + 1}${t('行目')} : ${t('重複があります。同じ言葉を使用することはできません。')}`);
            hasError = true;
            continue;
          }
        }
        if (hasError) {
          continue;
        }
        data.push(datum);
      }
      if (errors.length > 0) {
        alert(errors.join('\n'));
        event.target.value = '';
        return false;
      }
      //console.log(data)
      for (const datum of data) {
        await createUserSttDictionaries(UserInfo.id, datum);
      }
      const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
      setData(sttDictionaries);
      alert(t('取り込みが完了しました'));
      event.target.value = '';
      return true;
    } catch (error: any) {
      console.error(`エラー: ${error.toString()}`);
      alert(t('エラーが発生しました。再度お試しください。'));
      event.target.value = '';
      return false;
    }
  };

  const onClickAddTargetWord = async () => {
    if (!addTargetWordRef.current) {
      return;
    }

    const add: SttDictionariesFirebaseData = {
      targetWord: addTargetWordRef.current.value
    };
    const is_valid = await checkValidSttDictionaries(addTargetWordRef.current.value);
    if (!is_valid) {
      return;
    }
    await createUserSttDictionaries(UserInfo.id, add);
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    addTargetWordRef.current.value = '';
  }

  const onUpdate = async (update: SttDictionariesFirebaseData, updateString?: string): Promise<boolean> => {
    const merge = [...data];
    const index = merge.findIndex(w => w.id === update.id);
    if(index === -1){
      return false;
    }
    merge[index] = update;
    if (updateString) {
      const is_valid = await checkValidSttDictionaries(updateString, update.id);
      if (!is_valid) {
        return false;
      }
    }
    await updateUserSttDictionaries(UserInfo.id, update);
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    return true;
  }

  const onDelete = async (id: string): Promise<boolean> => {
    await deleteUserSttDictionaries(UserInfo.id, id);
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    return true;
  }

  const onChangeSearchKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.currentTarget.value);
  }

  useEffect(() => {
    getUserSttDictionaries(UserInfo.id).then((sttDictionaries) => {
      setData(sttDictionaries);
      defaultRef.current = sttDictionaries;
    });
  }, []);

  useEffect(() => {
    getUserSttDictionaries(UserInfo.id).then((sttDictionaries) => {
      defaultRef.current = sttDictionaries;
      if(keyword === ''){
        setData(sttDictionaries);
        return;
      }
      const keywords = keyword.split(separatorString);
      const filtered: SttDictionariesFirebaseData[] = [];
      for (const datum of defaultRef.current) {
        const matchesKeyword = keywords.every(keyword =>
            datum.targetWord.includes(keyword) || (datum.sourceWords && datum.sourceWords?.some((sourceWord) => sourceWord.includes(keyword)))
        );
        if (matchesKeyword) {
          filtered.push(datum);
        }
      }
      setData(filtered);
    });
  }, [keyword]);

  return (
      <div className='container p-4 p-md-4'>
        <div className='row mb-3'>
          <div className='col-auto h1 text-primary mb-0'>
            <b>{t('辞書登録')}</b>
          </div>
          <div className='col text-donut'>
            {t('内容を入力したら、項目ごとに「追加」「変更」ボタンをクリックしてください')}
          </div>
        </div>
        <div className="row mb-3 g-3">
          <div className="col-2">
            <button type="button"
                    className="btn btn-purple text-light w-100"
                    onClick={onClickUploadButton}
            >{t('一括取込')}</button>
            <input ref={fileInputRef}
                   onChange={onChangeFile}
                   className="d-none"
                   accept='text/csv'
                   multiple={false}
                   type="file" />
          </div>
          <div className="col-10">
            <div className="input-group">
              <div className="input-group-text"><i className="bi bi-search"></i></div>
              <input type="text"
                     className="form-control"
                     onChange={onChangeSearchKeyword}
                     value={keyword}
                     placeholder={t('登録済の辞書を検索...')}/>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="accordion" id="accordionWords">
            {data.map((datum) => (
                <TargetWord key={datum.id}
                            id={datum.id}
                            targetWord={datum.targetWord}
                            sourceWords={datum.sourceWords}
                            onUpdate={onUpdate}
                            onDelete={onDelete} />
            ))}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <div className="accordion-button collapsed accordion-add-button">
                  <div className="row g-3 w-100 pe-2">
                    <div className="col-10">
                      <input className="form-control"
                             type="text"
                             ref={addTargetWordRef}
                             placeholder={t('辞書追加...')} />
                    </div>
                    <div className="col-2">
                      <button type="button"
                              className="btn btn-primary w-100"
                              onClick={onClickAddTargetWord}
                      >{t('追加')}</button>
                    </div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
      </div>
  )
})
