import './IncreaseMemberLimit.css'

import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SettingRow } from './SettingRow'
import { useTextInput } from '../../../hook/useTextInput'
import { show } from '../../../view/PopupEvent'
import Api from '../../../api/Api'
import { GET_MEMBER_LIMIT_PRICES } from '../../../constant/Endpoints'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export const IncreaseMemberLimit = ({
  onItemClick }: { onItemClick?: (memCount: number) => void },
) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [memCount, handleMemCountChange, memCountRef] = useTextInput('1')

  const applyMemCount = useCallback(async () => {
    try {
      setLoading(true)

      // check price
      const memCount = parseInt(memCountRef.current) || 0
      const response = await Api.post(GET_MEMBER_LIMIT_PRICES, { memCount })
      const price = response.data.price

      if (price) {
        const ok = await show({
          content: t('お支払い金額', { price: formatter.format(price) }),
        })

        if (ok) {
          onItemClick?.(memCount)
        }
      } else {
        throw new Error('エラーが発生しました')
      }
    } catch (err) {
      console.log('[IncreaseMemberLimit] applyMemCount', err)
      show({
        content: t(`${(err as any)?.message || err}`),
      })
    } finally {
      setLoading(false)
    }
  }, [memCountRef, onItemClick, t])

  return (
    <SettingRow title={t('追加メンバー数')} value={memCount} onChange={handleMemCountChange}
      type='number' label={t('購入')} onClick={memCount ? applyMemCount : undefined} disabled={loading} />
  )
}
