import { useTranslation } from "react-i18next"

export const Guide = () => {
  const { t } = useTranslation()

  return (
    <div className='text-donut p-4'>
      <h1 className='my-4'>{t('使用方法・マニュアル')}</h1>

      <p>
        {t('使用マニュアルは以下からダウンロードできます。')}
      </p>

      <a href="/donutAI(議事録ver.)操作マニュアル.pdf" download="donutAI(議事録ver.)操作マニュアル.pdf">
        <b>
          {t('ダウンロード(日本語版)')}
        </b>
      </a>
      <p>(PDF, 2.31MB 2023/09/19)</p>

      <a href="/Manual_English2024_08_20.pdf" download="Manual_English2024_08_20.pdf">
        <b>
          {t('ダウンロード(英語版)')}
        </b>
      </a>
      <p>(PDF, 8.4MB 2024/10/30)</p>
    </div>
  )
}
