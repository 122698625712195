import { useEffect, useState } from 'react'

/**
 * Shamelessly stolen media query API from:
 * https://zenn.dev/nabeliwo/articles/89099b39223eca
 */

/**
 * https://getbootstrap.jp/docs/5.3/layout/breakpoints/
 */
export const bootstrapQuery = {
  // Extra Small
  xs: 'width < 576px',
  // Small
  sm:  '576px <= width',
  // Medium
  md:  '768px <= width',
  // Large
  lg:  '992px <= width',
  // Extra Large
  xl:  '1200px <= width',
  // Extra Extra Large
  xxl: '1400px <= width',
} as const


type DefaultMediaQuery = (
  typeof bootstrapQuery[keyof typeof bootstrapQuery]
)

/** 
* Returns True if given media query evaluates to true.
* Accompanied with bootstrapQuery, which follows the rule of bootstrap breakpoint.
*
* @example
* ```tsx
* const isAboveLarge = useMediaQuery(bootstrapQuery.lg);
*
* if (isAboveLarge) {
*   console.log("This device has more than 992px width!");
* } else {
*   console.log("This device has less than 992px width! maybe a tablet or phone?");
* }
* ```
* */
export function useMediaQuery(query: DefaultMediaQuery | string): boolean {
  const formattedQuery = `(${query})`
  const [match, setMatch] = useState(matchMedia(formattedQuery).matches)

  useEffect(() => {
    const media = matchMedia(formattedQuery)
    media.onchange = e => setMatch(e.matches)
  }, [formattedQuery])

  return match
}
