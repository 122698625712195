import queryString from 'query-string'

export const _parseQueryParam = (name: string, remove = true) => {
  const location = window.location
  const params = location.search

  let val = null
  if (params) {
    const parsed = queryString.parse(params)
    val = parsed[name]
    if (val && remove) {
      delete parsed[name]
      const newQuery = queryString.stringify(parsed)
      // remove param
      const newurl = location.protocol + '//' + location.host + location.pathname + (newQuery ? `?${newQuery}` : '')
      window.history.replaceState(window.history.state, '', newurl)
    }
  }

  return val
}

export const _padNumber = (num: number | string, charCount = 2) => {
  return `${num}`.padStart(charCount, '0')
}

export const _getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const CHARSET = [
  ...[...Array(26)].map((e, i) => String.fromCharCode('A'.charCodeAt(0) + i)),
  ...[...Array(26)].map((e, i) => String.fromCharCode('a'.charCodeAt(0) + i)),
  ...[...Array(10)].map((e, i) => String.fromCharCode('0'.charCodeAt(0) + i)),
]

export const _randomId = (chars = 12) => {
  let id = ''
  for (let i = 0; i < chars; i++) {
    id += CHARSET[_getRandomInt(0, CHARSET.length - 1)]
  }
  return id
}
