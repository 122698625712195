import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import {useTranslation} from "react-i18next";

export const Contract = observer(() => {
  const { t } = useTranslation()

  return (
    <div className="overflow-y-auto text-donut p-4">
      <Link to="/" className="mx-2 link-secondary">
        {t('戻る')}
      </Link>
      <h3 className="mb-4 text-center">利用料金及び契約期間について</h3>
      <p>ドーナッツロボティクス株式会社（以下「当社」といいます。）は、当社が提供する、リアルタイムでの会議の文字起こし、翻訳、議事録の自動生成等を可能にするサービス「donut AI（議事録ver.）」（理由の如何を問わずその名称又は内容が変更された場合は、当該変更後のサービスを含み、以下「本サービス」といいます。）の利用規約に基づき、利用料金、契約期間等について、以下のとおり定めます。</p>

      <h4 className="mb-4">1.利用料金</h4>
      <ol>
        <li>本サービスの利用料金は、以下のとおりとします。<br />
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">プラン名</th>
                <th className="text-center">定価（税抜き・月額）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">0プラン</td>
                <td className="text-end">0円</td>
              </tr>
              <tr>
                <td className="text-center">PERSONAL</td>
                <td className="text-end">1,364円</td>
              </tr>
              <tr>
                <td className="text-center">LITE</td>
                <td className="text-end">3,182円</td>
              </tr>
              <tr>
                <td className="text-center">BASIC</td>
                <td className="text-end">8,909円</td>
              </tr>
              <tr>
                <td className="text-center">STANDARD</td>
                <td className="text-end">27,091円</td>
              </tr>
              <tr>
                <td className="text-center">PRO</td>
                <td className="text-end">63,455円</td>
              </tr>
              <tr>
                <td className="text-center">ENTERPRISE</td>
                <td className="text-end">180,000円</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>上記（1）にかかわらず、当社と当社の代理店であるエレコム株式会社（以下「代理店」といいます。）が別途定めたキャンペーン期間において、代理店経由で契約事業者登録が行われる場合、当該登録日が属する月の利用料金は無料とし、契約事業者は、当該登録日が属する月の翌月以降分につき、上記（1）の利用料金を支払うものとします。</li>
        <li>契約事業者は、当月分の利用料金を、翌月末日までに支払うものとします。</li>
      </ol>
      <h4 className="mb-4">2.プランの変更（ただし、0プランへの変更を除きます。）</h4>
      <p>契約事業者が代理店又は代理店の委託先（以下「代理店等」といいます。）に対し本サービスのプランの変更（ただし、0プランへの変更を除きます。）の申し出をした場合、当該申し出から3営業日以内に代理店等が当社に通知を行います。契約事業者は、毎月20日以前に当該通知が行われた場合、当該通知が行われた日の翌月1日から変更後のプランを利用できるものとし、当該月以降分の利用料金から変更後のプランにかかる利用料金が適用されるものとします。また、契約事業者は、毎月21日以降に当該通知が行われた場合、当該通知が行われた日の翌々月1日から変更後のプランを利用できるものとし、当該月以降分の利用料金から変更後のプランにかかる利用料金が適用されるものとします。</p>
      <h4 className="mb-4">3.有料プランの解約（有料プランから0プランへの変更）</h4>
      <p>契約事業者が代理店等に対し有料プランの解約（有料プランから0プランへの変更を意味します。）の申し出をした場合において、当該申し出が毎月20日以前に行われたとき、当該申し出が行われた日の翌月1日をもって、有料プランの解約の効果が生じ、0プランに変更されるものとし、別途プランの変更が行われない限り、当該月分以降の利用料金が無料となります。また、契約事業者による代理店等に対する有料プランの解約の申し出が毎月21日以降に行われた場合、当該申し出が行われた日の翌々月1日をもって、有料プランの解約の効果が生じ、0プランに変更されるものとし、別途プランの変更が行われない限り、当該月分以降の利用料金が無料となります。</p>
      <h4 className="mb-4">4.契約期間</h4>
      <p>本サービスの契約期間は1か月とし、利用規約に従い契約事業者登録の抹消その他本契約（利用規約に定義される。）を終了させる事由が生じない限り、1か月間自動的に同一条件で更新されるものとし、以後も同様とします。また、プランの変更又は有料プランの解約が行われない限り、1か月間自動的に同一プランでの契約が更新されるものとし、以後も同様とします。</p>
      <h4 className="mb-4">5.契約事業者登録の抹消（本サービスの利用の終了）</h4>
      <p>契約事業者が代理店等に対し、契約事業者登録の抹消の申し出をした場合、当該申し出から3営業日以内に代理店等が当社に通知を行い、当該通知を踏まえ当社が契約事業者登録の抹消の処理を行った日（当該通知から3営業日以内に行われるものとします。）から30日後に契約事業者登録の抹消の効果が発生するものとします。</p>
    </div>
  )
})
