import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import UserInfo from "../../store/UserInfo"
import { BUTTON_MODES, show } from "../../view/PopupEvent"
import { useTranslation } from "react-i18next"


export const Contact = observer(() => {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [category, setCategory] = useState(t('製品に関するお問い合わせ'))
  const [content, setContent] = useState('')
  const [isPosting, setIsPosting] = useState(false)

  const onSubmitAction = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsPosting(true)
    e.preventDefault()
    e.stopPropagation()

    if (e.currentTarget.checkValidity()) {
      const ok = await UserInfo.sendContactMessage(email, name, category, content)
      if (ok) {
        await show({
          title: t("成功"),
          content: t("問い合わせを送信しました。"),
          btnMode: BUTTON_MODES.OK,
        })
      }
    }
    setIsPosting(false)
  }

  return (
    <div className='container p-4 p-md-4 text-donut'>
      {UserInfo.loggedIn
        ? (
          null
        )
        : (
          <Link to="/" className="link-secondary">
            {t('戻る')}
          </Link>
        )
      }

      <div className='row mb-4'>
        <h1 className='col-md-auto col-sm-12 text-primary mb-0'>
          <b>{t('お問い合わせ')}</b>
        </h1>
        <div className='col-md-auto col-sm-12 text-donut'>
          {t('頂いたお問い合わせには、3-5営業日以内に担当者からご返答を差し上げます。')}
        </div>
      </div>

      <form className="row" onSubmit={onSubmitAction}>
        <div className="col-8 mb-3 row">
          <label htmlFor="title" className="col-sm-4 col-md-3 col-form-label">
            {t('お名前')}
          </label>
          <div className="col-sm-8 col-md-9">
            <input
              required
              id="title"
              type="text"
              className="form-control"
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="col-8 mb-3 row">
          <label htmlFor="email" className="col-sm-4 col-md-3 col-form-label">
            {t("メールアドレス")}
          </label>
          <div className="col-sm-8 col-md-9">
            <input
              required
              id="email"
              type="email"
              className="form-control"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="col-8 mb-3 row">
          <label htmlFor="category" className="col-sm-4 col-md-3 col-form-label">
            {t("カテゴリー")}
          </label>
          <div className="col-sm-8 col-md-9">
            <select
              required
              id="category"
              className="form-select"
              onChange={e => setCategory(e.currentTarget.value)}
              value={category}
            >
              <option value={t('製品に関するお問い合わせ')}>{t('製品に関するお問い合わせ')}</option>
              <option value={t('解約に関するお問い合わせ')}>{t('解約に関するお問い合わせ')}</option>
              <option value={t('お支払いに関するお問い合わせ')}>{t('お支払いに関するお問い合わせ')}</option>
              <option value={t('その他のお問い合わせ')}>{t('その他のお問い合わせ')}</option>
            </select>
          </div>
        </div>

        <div className="col-8 mb-3 row">
          <label htmlFor="content" className="col-12 col-form-label">
            {t('内容')}
          </label>
          <div className="col-sm-8 col-md-12">
            <textarea
              required
              id="content"
              className="form-control"
              style={{ borderRadius: "10px" }}
              value={content}
              onChange={e => setContent(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="col-8 mb-3">
          <button type="submit" disabled={isPosting} className="btn btn-primary">
            { isPosting ? t("送信中…") : t("送信") }
          </button>
        </div>
      </form>
    </div>
  )
})
