import MyTippy from "./MyTippy"
import { useCopyToClipboard } from "../hook/useCopyToClipboard"
import { useTranslation } from "react-i18next"

type CopyToClipboardProps = {
  value: string,
  disabled?: boolean,
} & JSX.IntrinsicElements['button']

const CopyToClipboard = ({ value, disabled = false, ...elementProps }: CopyToClipboardProps) => {
  const { t } = useTranslation()
  const [isCopying, doCopy] = useCopyToClipboard(value)

  const className = (
    'btn badge rounded-pill ' 
    + (disabled ? 'text-donut' : 'btn-primary')
    + ' '
    + (elementProps.className ?? ''))

  const onClick = () => {
    if (!disabled) {
      doCopy()
    }
  }

  const content = (
    <button
      {...elementProps}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      <i className="fa-fw fa-solid fa-copy" />
    </button>
  )

  // Tippy shouldn't trigger tooltip when in disabled.
  if (disabled) {
    return content
  }

  const message = isCopying ? t("コピーしました。") : t("コピーする")

  // wrap content in tippy
  return (
    <MyTippy content={message} hideOnClick={false} arrow>
      {content}
    </MyTippy>
  )
}

export default CopyToClipboard
