import ja_JP from '../assets/flags/jp.png'
import en_US from '../assets/flags/us.png'
import es_ES from '../assets/flags/es.png'
import zh_CN from '../assets/flags/cn.png'
import ko_KR from '../assets/flags/kr.png'
import fr_FR from '../assets/flags/fr.png'
import th_TH from '../assets/flags/th.png'
import id_ID from '../assets/flags/id.png'
import vi_VN from '../assets/flags/vn.png'
import is_IS from '../assets/flags/is.png'
import en_IE from '../assets/flags/ie.png'
import ar_AE from '../assets/flags/ae.png'
import ar_DZ from '../assets/flags/dz.png'
import es_AR from '../assets/flags/ar.png'
import ar_YE from '../assets/flags/ye.png'
import en_GB from '../assets/flags/gb.png'
import ar_IL from '../assets/flags/il.png'
import ar_MA from '../assets/flags/ma.png'
import iw_IL from '../assets/flags/il.png'
import it_IT from '../assets/flags/it.png'
import ar_IQ from '../assets/flags/iq.png'
import en_IN from '../assets/flags/in.png'
import hi_IN from '../assets/flags/in.png'
import te_IN from '../assets/flags/in.png'
import uk_UA from '../assets/flags/ua.png'
import es_UY from '../assets/flags/uy.png'
import es_EC from '../assets/flags/ec.png'
import ar_EG from '../assets/flags/eg.png'
import et_EE from '../assets/flags/ee.png'
import es_SV from '../assets/flags/sv.png'
import ar_OM from '../assets/flags/om.png'
import nl_NL from '../assets/flags/nl.png'
import en_AU from '../assets/flags/au.png'
import de_AT from '../assets/flags/at.png'
import ar_QA from '../assets/flags/qa.png'
import en_CA from '../assets/flags/ca.png'
import fr_CA from '../assets/flags/ca.png'
import en_GH from '../assets/flags/gh.png'
import el_GR from '../assets/flags/gr.png'
import ar_KW from '../assets/flags/kw.png'
import hr_HR from '../assets/flags/hr.png'
import es_GT from '../assets/flags/gt.png'
import en_KE from '../assets/flags/ke.png'
import es_CR from '../assets/flags/cr.png'
import es_CO from '../assets/flags/co.png'
import ar_SA from '../assets/flags/sa.png'
import en_SG from '../assets/flags/sg.png'
import fr_CH from '../assets/flags/ch.png'
import de_CH from '../assets/flags/ch.png'
import it_CH from '../assets/flags/ch.png'
import sv_SE from '../assets/flags/se.png'
import ca_ES from '../assets/flags/es.png'
import ta_LK from '../assets/flags/lk.png'
import sk_SK from '../assets/flags/sk.png'
import sl_SI from '../assets/flags/si.png'
import sr_RS from '../assets/flags/rs.png'
import en_TZ from '../assets/flags/tz.png'
import cs_CZ from '../assets/flags/cz.png'
import ar_TN from '../assets/flags/tn.png'
import es_CL from '../assets/flags/cl.png'
import da_DK from '../assets/flags/dk.png'
import tr_TR from '../assets/flags/tr.png'
import de_DE from '../assets/flags/de.png'
import es_DO from '../assets/flags/do.png'
import en_NG from '../assets/flags/ng.png'
import es_NI from '../assets/flags/ni.png'
import en_NZ from '../assets/flags/nz.png'
import no_NO from '../assets/flags/no.png'
import hu_HU from '../assets/flags/hu.png'
import bn_BD from '../assets/flags/bd.png'
import ar_BH from '../assets/flags/bh.png'
import ur_PK from '../assets/flags/pk.png'
import es_PA from '../assets/flags/pa.png'
import es_PY from '../assets/flags/py.png'
import ar_PS from '../assets/flags/ps.png'
import en_PH from '../assets/flags/ph.png'
import fil_P from '../assets/flags/ph.png'
import fi_FI from '../assets/flags/fi.png'
import pt_BR from '../assets/flags/br.png'
import bg_BG from '../assets/flags/bg.png'
import es_PR from '../assets/flags/pr.png'
import es_VE from '../assets/flags/ve.png'
import nl_BE from '../assets/flags/be.png'
import fr_BE from '../assets/flags/be.png'
import es_PE from '../assets/flags/pe.png'
import es_HN from '../assets/flags/hn.png'
import es_BO from '../assets/flags/bo.png'
import pt_PT from '../assets/flags/pt.png'
import pl_PL from '../assets/flags/pl.png'
import ms_MY from '../assets/flags/my.png'
import es_MX from '../assets/flags/mx.png'
import ar_JO from '../assets/flags/jo.png'
import lv_LV from '../assets/flags/lv.png'
import lt_LT from '../assets/flags/lt.png'
import ro_RO from '../assets/flags/ro.png'
import ar_LB from '../assets/flags/lb.png'
import ru_RU from '../assets/flags/ru.png'
import zh_HK from '../assets/flags/hk.png'
import zh_TW from '../assets/flags/tw.png'
import af_ZA from '../assets/flags/za.png'

export const Flags = {
  ja_JP,
  en_US,
  es_ES,
  zh_CN,
  ko_KR,
  fr_FR,
  th_TH,
  id_ID,
  vi_VN,
  is_IS,
  en_IE,
  ar_AE,
  ar_DZ,
  es_AR,
  ar_YE,
  en_GB,
  ar_IL,
  ar_MA,
  iw_IL,
  it_IT,
  ar_IQ,
  en_IN,
  hi_IN,
  te_IN,
  uk_UA,
  es_UY,
  es_EC,
  ar_EG,
  et_EE,
  es_SV,
  ar_OM,
  nl_NL,
  en_AU,
  de_AT,
  ar_QA,
  en_CA,
  fr_CA,
  en_GH,
  el_GR,
  ar_KW,
  hr_HR,
  es_GT,
  en_KE,
  es_CR,
  es_CO,
  ar_SA,
  en_SG,
  fr_CH,
  de_CH,
  it_CH,
  sv_SE,
  ca_ES,
  ta_LK,
  sk_SK,
  sl_SI,
  sr_RS,
  en_TZ,
  cs_CZ,
  ar_TN,
  es_CL,
  da_DK,
  tr_TR,
  de_DE,
  es_DO,
  en_NG,
  es_NI,
  en_NZ,
  no_NO,
  hu_HU,
  bn_BD,
  ar_BH,
  ur_PK,
  es_PA,
  es_PY,
  ar_PS,
  en_PH,
  fil_P,
  fi_FI,
  pt_BR,
  bg_BG,
  es_PR,
  es_VE,
  nl_BE,
  fr_BE,
  es_PE,
  es_HN,
  es_BO,
  pt_PT,
  pl_PL,
  ms_MY,
  es_MX,
  ar_JO,
  lv_LV,
  lt_LT,
  ro_RO,
  ar_LB,
  ru_RU,
  zh_HK,
  zh_TW,
  af_ZA,
}