import { type FormEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { _parseQueryParam } from '../../util/Util'
import UserInfo from '../../store/UserInfo'
import { BUTTON_MODES, show } from '../../view/PopupEvent'
import { useTranslation } from 'react-i18next'

export const ResetPassword = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const passwordRef = useRef<HTMLInputElement>(null)

  const token = useMemo(() => {
    const t = _parseQueryParam('token', false)
    return Array.isArray(t) ? t[0] : t
  }, [])

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault()
    const password = passwordRef.current?.value.trim()
    if (password && token) {
      setLoading(true)
      UserInfo.resetPassword(token, password).then((ok) => {
        if (ok) {
          history.push('/login')
        } else {
          show({
            content: t('エラーが発生しました'),
            btnMode: BUTTON_MODES.OK,
            staticBackdrop: false,
          })
          setLoading(false)
        }
      }).catch(e => {
        show({
          content: t(e.response.data.message),
          btnMode: BUTTON_MODES.OK,
          staticBackdrop: false,
        })
        setLoading(false)
      })
    }
  }, [history, token, t])

  return (
    <div className='container p-3' style={{ overflowY: 'auto' }}>
      <div className='row justify-content-center mt-4 mt-md-5'>
        <div className='login-box donut-box col col-sm-10 col-md-8 col-lg-6 col-xl-5 p-5 pt-4'>
          <form className='email-provider-form d-flex flex-column align-items-center' onSubmit={handleSubmit}>
            <div className='form-group mb-2 mt-4' style={{ width: '100%' }}>
              <div className='d-flex justify-content-between' style={{ flexWrap: 'wrap' }}>
                <label htmlFor='signin-password' className='text-primary ms-3'>
                  <b>{t('パスワード')}</b>
                </label>
              </div>
              <input type='password' className='form-control' id='signin-password'
                ref={passwordRef} placeholder={'パスワード'} />
            </div>
            <div className='d-flex flex-column align-items-stretch'>
              <button type='submit' disabled={loading}
                className='btn btn-primary bg-grad-primary mt-4 px-5'>
                {t('パスワードの再設定')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
