import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UserInfo from '../../../store/UserInfo'
import { PlanItem } from './PlanItem'

type PlanSelectionProps = JSX.IntrinsicElements['div'] & {
  plans: Array<any>,
  selectedPlanId: string,
  setSelectedPlan: (a: string) => void
}

export const PlanSelection = observer(({ plans, selectedPlanId, setSelectedPlan }: PlanSelectionProps) => {
  const { t } = useTranslation()
  const [selectedId, setSelectedId] = useState<string>(selectedPlanId)
  const selectingPlan = plans.find(n => selectedId === n.id)

  const onConfirm = () => {
    if (selectingPlan) {
      setSelectedPlan(selectedId)
    }
  }

  return (
    <div className='container'>
      {UserInfo.loggedIn && (
        <>
          <h2 className='my-4'>{t('現在選択中のプラン')}</h2>
          <div className='row g-2 m-2'>
            <div className='col-3'>
              <PlanItem p={selectingPlan}>
                <button
                  className='btn btn-primary card-link'
                  onClick={onConfirm}
                >
                  {t('確定')}
                </button>
              </PlanItem>
            </div>
          </div>
        </>
      )}

      <h2 className='my-4'>{t('選択可能なプラン一覧')}</h2>
      <div className='row g-2 m-2'>
        {plans.sort((p1, p2) => {
          // show UNLIMITED plans first
          const p1p = p1.amountJpy + (UserInfo.isUnlimitedPlan(p1) ? 1e6 : 0)
          const p2p = p2.amountJpy + (UserInfo.isUnlimitedPlan(p2) ? 1e6 : 0)
          return p2p - p1p
        }).map(p =>
          <div key={p.id} className='col-3'>
            <PlanItem p={p}>
              {UserInfo.loggedIn && (
                <button
                  className='btn btn-outline-secondary card-link'
                  onClick={() => setSelectedId(p.id)}
                >
                  {t('選択')}
                </button>
              )}
            </PlanItem>
          </div>
        )}
      </div>
    </div>
  )
})
