import { MEETING_BEGIN_URL, MEETING_CREATE_URL, MEETING_FINISH_URL } from '../constant/Endpoints'
import Api from './Api'
import i18next from "i18next";


/**
 * ミーティングURLを作成する
 * @param title - ミーティングタイトル
 * @param scheduledBeginAt - 開始予定日時
 * @param scheduledEndAt - 終了予定日時
 * @param allocatedTime - ミーティング時間
 * @param enableAIAdvisor - AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
 * @param agendaFiles - アジェンダ資料
 * @param files - その他の会議資料
 */
export const createMeetingUrl = async (title: string, scheduledBeginAt: string, scheduledEndAt: string, allocatedTime: number, enableAIAdvisor: boolean, agendaFiles?: FileList | null | undefined, files?: FileList | null | undefined) => {
  let additionalDocument = '';
  if(agendaFiles && agendaFiles.length !== 0){
    additionalDocument = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const segmenter = new Intl.Segmenter("ja", { granularity: "grapheme" });
        // @ts-ignore
        if([...segmenter.segment(result)].length > 3000){
          reject(i18next.t('テキストファイルの文字数は3000文字以内にしてください'));
        }
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(agendaFiles[0]);
    });
  }

  const formData = new FormData();
  if(files && files.length !== 0){
    // ファイルの追加
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  }
  formData.append('title', title);
  formData.append('scheduledBeginAt', scheduledBeginAt);
  formData.append('scheduledEndAt', scheduledEndAt);
  formData.append('allocatedTime', allocatedTime.toString());
  formData.append('enableAIAdvisor', enableAIAdvisor ? '1': '0');
  formData.append('additionalDocument', additionalDocument);
  formData.append('domain', process.env.REACT_APP_FIREBASE_PROJECTID!);

  return Api.post(MEETING_CREATE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const finishMeeting = (meetingId: string) => {
  return Api.post(MEETING_FINISH_URL, { meetingId, domain: process.env.REACT_APP_FIREBASE_PROJECTID })
}

export const beginMeeting = (meetingId: string) => {
  return Api.post(MEETING_BEGIN_URL, { meetingId, domain: process.env.REACT_APP_FIREBASE_PROJECTID })
}
