import { Trans } from 'react-i18next'

export type CardTokenResult = {
  lastDigit: string,
  expiry: string,
  name: string,

  token?: string,
}

export const CardItem = ({ card, children }: { card: CardTokenResult, children: React.ReactNode }) => {
  const replacedName = card.name.slice(0, 4) + (new Array(card.name.length - 4).fill('*').join(''))
  const cardExpiry = card.expiry

  return (
    <div className={'card'}>
      <div className='card-body'>
        <h5 className='card-title'>****-****-****-{card.lastDigit}
        </h5>
        <p className='text-donut subtitle'>
          <Trans>
            期限 **年 {{ cardExpiry }} 月
          </Trans>
        </p>
        <p className='text-donut'>
          <Trans>
            {{ replacedName }} 名義
          </Trans>
        </p>
        {children}
      </div>
    </div>
  )
}
