// inputタグ datetime-local属性のフォーマットに変換
import i18next from "i18next";

export function convertDateTimeToString(dt: Date | string, addMinutes: null | number = null) {
  const date = new Date(dt);
  if(addMinutes !== null){
    date.setMinutes(date.getMinutes() + addMinutes);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

// 画面に表示する日付のフォーマットに変換
export function convertDateTimeToStringForView(dt: Date | string, addMinutes: null | number = null) {
  const date = new Date(dt);
  if(addMinutes !== null){
    date.setMinutes(date.getMinutes() + addMinutes);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  try {
    return `${year}${i18next.t('date.year')}${month}${i18next.t('date.month')}${day}${i18next.t('date.day')} ${hours}:${minutes}`;
  }catch (e){
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }
}

export function calcDiffMinutes(begin: Date | string, end: Date | string) {
  const begin_date = new Date(begin);
  const end_date = new Date(end);
  const differenceInMs = Math.abs(end_date.getTime() - begin_date.getTime());
  return Math.floor(differenceInMs / 1000 / 60);
}
