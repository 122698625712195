import {
  type Dispatch,
  type MutableRefObject,
  type SetStateAction,
  type ChangeEventHandler,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'

import { PROPERTY_CHAR_LIMIT } from '../store/UserInfo'

export const useTextInput = (initialValue = '', charLimit = PROPERTY_CHAR_LIMIT): [string, ChangeEventHandler<HTMLInputElement>, MutableRefObject<string>, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState(initialValue)
  const valueRef = useRef(initialValue)
  useEffect(() => {
    valueRef.current = value
  }, [value])

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setValue(e.target.value.substring(0, charLimit))
  }, [charLimit])

  return [value, handleValueChange, valueRef, setValue]
}
