import { Suspense, useCallback, useEffect, useReducer, useRef } from 'react'

import { type Event } from '../util/EventDispatcher'
import { ACTIONS, BUTTON_MODES, POPUP_DEFAULT, addPopupListener } from './PopupEvent'
import { DelayTask } from '../util/DelayTask'

const ID = 'general-popup'

export type PopupOptions = {
  title?: any
  content?: any
  btnMode?: string
  contentReady?: boolean | Promise<boolean>
  resolve?: (result: any) => any
  reject?: (error: any) => any
  show?: boolean
  okText?: string
  cancelText?: string
  okAction?: (result: any) => any
  size?: string
  staticBackdrop?: boolean
}

const reducer = (state: PopupOptions, event: Event<never, PopupOptions, any>) => {
  switch (event.action) {
    case ACTIONS.SHOW:
      return {
        ...state,
        ...event.data,
        show: true,
      }
    case ACTIONS.HIDE:
      // no need to reset state here because default state is applied befor SHOW
      return {
        ...state,
        show: false,
      }
    case ACTIONS.MANUAL_UPDATE:
      return {
        ...state,
        ...event.data,
      }
    default:
      console.warn(`[Popup] unknown action ${event.action}`)
      return state
  }
}

const ShowHideTask = new DelayTask<{ show: boolean | undefined, modal: any }>(50)
ShowHideTask.onTrigger = (data) => {
  const { show, modal } = data || {}
  show ? modal?.show() : modal?.hide()
}

function Popup() {
  const [state, dispatch] = useReducer(reducer, POPUP_DEFAULT)
  const { show, contentReady } = state

  const resolveRef = useRef(state.resolve)
  const okActionRef = useRef(state.okAction)
  useEffect(() => {
    resolveRef.current = state.resolve
    okActionRef.current = state.okAction
  }, [state.okAction, state.resolve])

  // Bootstrap modal ref
  const backdropRef = useRef<HTMLDivElement>(null)
  const bootstrapModalRef = useRef<any>(null)
  useEffect(() => {
    const bootstrapModal = new ((window as any).bootstrap.Modal)(`#${ID}`)
    bootstrapModalRef.current = bootstrapModal
  }, [])

  useEffect(() => {
    ShowHideTask.trigger({ show, modal: bootstrapModalRef.current })
    if (contentReady instanceof Promise) {
      contentReady.then(() => {
        dispatch({
          action: ACTIONS.MANUAL_UPDATE,
          data: {
            contentReady: true,
          },
        })
      })
    }
  }, [show, contentReady])

  const handleCancel = useCallback(() => {
    resolveRef.current && resolveRef.current(false)
    dispatch({ action: ACTIONS.HIDE })
  }, [])

  const handleConfirm = useCallback(async (result: any) => {
    const resolve = resolveRef.current
    const okAction = okActionRef.current

    // wait for ok result
    if (okAction) {
      result = await okAction(result)
    }

    // finalizing
    dispatch({ action: ACTIONS.HIDE })
    resolve && resolve(result)
  }, [])

  useEffect(() => {
    const removePopupListener = addPopupListener((event: any) => {
      if (event.action === ACTIONS.HIDE && !!event.data) {
        handleConfirm(event.data)
      } else {
        if (event.action === ACTIONS.SHOW) {
          // ensure old content being cleared before new content are showed
          dispatch({
            action: ACTIONS.MANUAL_UPDATE,
            data: {
              title: null,
              content: null,
            },
          })
        }
        // delay dispatch to make sure content being cleared first
        setTimeout(() => {
          dispatch(event)
        }, 0)
      }
    })

    return () => {
      removePopupListener()
    }
  }, [handleConfirm])

  useEffect(() => {
    const closeListener = () => {
      resolveRef.current && resolveRef.current(false)
      dispatch({ action: ACTIONS.HIDE })
    }
    bootstrapModalRef.current?._element.addEventListener('hidden.bs.modal', closeListener)

    return () => {
      bootstrapModalRef.current?._element.removeEventListener('hidden.bs.modal', closeListener)
    }
  }, [handleCancel])

  // handle backdrop click
  const handleBackdropClick = useCallback((e: any) => {
    if (e.target !== backdropRef.current) {
      return
    }
    if (!state.staticBackdrop) {
      handleCancel()
    }
  }, [handleCancel, state.staticBackdrop])

  return (
    <div id={ID} className='modal fade' tabIndex={-1} role='dialog' aria-hidden='true' data-bs-backdrop='static' ref={backdropRef} onClick={handleBackdropClick}>
      <div className={`modal-dialog modal-dialog-centered ${state.size}`} role='document'>
        <div className='modal-content'>
          {state.title && (
            <div className='modal-header'>
              <h4 className='modal-title' style={{ flexBasis: 'auto' }}>{state.title}</h4>
              <div className='flex-fill'>{/* Spacer */}</div>
              <button type='button' className='btn-close' aria-label='Close'
                onClick={handleCancel}>
              </button>
            </div>
          )}
          <div className='modal-body' style={{ wordWrap: 'break-word' }}>
            <Suspense fallback={(
              <div className='container'>
                <div className='row justify-content-center my-3'>
                  <div className='col-auto'>
                    <div className='spinner-border color-focus' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            )}>
              {state.content}
            </Suspense>
          </div>
          {state.btnMode !== BUTTON_MODES.NONE && (
            <div className={`modal-footer${(state.btnMode === BUTTON_MODES.OK || state.btnMode === BUTTON_MODES.CANCEL) ? ' justify-content-center' : ''}`}>
              {state.btnMode !== BUTTON_MODES.OK && (
                <button type='button' className='btn btn-secondary'
                  onClick={handleCancel}>
                  {state.cancelText}
                </button>
              )}
              {state.btnMode !== BUTTON_MODES.CANCEL && (
                <button type='button' className='btn btn-primary' onClick={() => handleConfirm(true)}
                  disabled={contentReady === false || contentReady instanceof Promise}>
                  {state.okText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Popup
