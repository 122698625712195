import { useState } from 'react'
import { useHistory } from 'react-router-dom';

type DivProps = JSX.IntrinsicElements['div']
interface SettingBlockProps extends DivProps {
  title?: string
  onStateChange?: (expanded: boolean) => void
  initialState?: boolean,
  link?: string,
}

export const SettingBlock = ({ title, children, onStateChange = undefined, initialState = false, link = undefined }: SettingBlockProps) => {
  const [expand, setExpand] = useState(initialState)

  const history = useHistory();

  const toggleExpand = () => {
    if (link) {
      history.push(link);
    } else {
      setExpand(!expand)
      onStateChange?.(!expand)
    }
  }

  return (
    <div className='setting-block'>
      <div className='text-primary title' onClick={toggleExpand}>
        {!link && (<i className={`fa-solid fa-chevron-${expand ? 'up' : 'down'} me-2`} />)}
        <b className={link ? 'ms-4' : ''}>{title}</b>
      </div>
      {!!expand && (
        <div className='container-fluid ps-md-5'>
          {children}
        </div>
      )}
    </div>
  )
}
