import React from 'react';
import './LoadingOverlay.css';

export const LoadingOverlay = ({ active }: {active: boolean}) => {
    if (!active) return null;
  return (
      <div className="loading-overlay">
          <div className="loading-icon"></div>
      </div>
  )
}
