import { useCallback, useEffect, useRef, useState } from 'react'

const KEY_PREFEX = 'countdown.'

export const useCountDown = (max = 0, saveKey = ''): [number, (seconds?: number, save?: boolean) => void, React.MutableRefObject<number>] => {
  const countDownTask = useRef<NodeJS.Timer | undefined>(undefined)
  const [waitTime, setWaitTime] = useState(0)

  const waitTimeRef = useRef(waitTime)
  useEffect(() => {
    waitTimeRef.current = waitTime
  }, [waitTime])

  const stopCountDown = useCallback(() => {
    clearInterval(countDownTask.current)
  }, [])

  const startCountDown = useCallback((seconds?: number, save = true) => {
    clearInterval(countDownTask.current)
    if (!seconds) {
      seconds = max
    }
    if (save) {
      localStorage.setItem(`${KEY_PREFEX}${saveKey}`, `${new Date().getTime()}`)
    }

    setWaitTime(seconds)
    countDownTask.current = setInterval(() => {
      setWaitTime((t) => {
        if (t <= 1) {
          clearInterval(countDownTask.current)
        }
        return Math.max(t - 1, 0)
      })
    }, 1000)
  }, [max, saveKey])

  useEffect(() => {
    // load initial state
    if (saveKey) {
      const lastStart = parseInt(localStorage.getItem(`${KEY_PREFEX}${saveKey}`) || '0')
      const now = new Date().getTime()

      const timeLeft = max * 1000 - (now - lastStart)
      if (timeLeft > 0) {
        startCountDown(Math.ceil(timeLeft / 1000), false)
      } else {
        setWaitTime(0)
      }
    }

    return () => {
      stopCountDown()
    }
  }, [max, saveKey, startCountDown, stopCountDown])

  return [waitTime, startCountDown, waitTimeRef]
}
