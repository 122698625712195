import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

export interface LoadingButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: string;
  isLoading?: boolean;
  big?: boolean;
  small?: boolean;
  buttonStyle?: string;
}

function LoadingButton({
  icon = '',
  children,
  buttonStyle,
  disabled = false,
  isLoading = false,
  big = false,
  small = false,
  className = '',
  ...props
}: LoadingButtonProps) {
  return (
    <button className={`btn btn-${buttonStyle || 'primary'}${big ? ' btn-lg ' : (small ? ' btn-sm' : '')} ${className}`}
      disabled={disabled || isLoading} {...props}>
      {isLoading && <i className='fas fa-sync fa-spin me-1' />}
      {children}
    </button>
  )
}

export default LoadingButton
