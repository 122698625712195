import {ILanguages, LanguageList} from "../../constant/Languages";
import {Flag} from "../../view/Flag";
import React from "react";
import {useTranslation} from "react-i18next";

export const EditMessage = ({lang, text, onEdit}: {lang: ILanguages, text: string, onEdit: (text: string) => void}) => {
  const { t } = useTranslation();

  const onClickOpenMessage = (event: React.MouseEvent) => {
    // 開くボタンを隠す
    event.currentTarget.className += ' d-none';

    const parent = event.currentTarget.parentElement;
    if(parent){
      // 保存用ボタンを表示
      const save = parent.querySelector('.save');
      if(save){
        save.className = save.className.replace('d-none', '');
      }
      // 閉じる用ボタンを表示
      const close = parent.querySelector('.close');
      if(close){
        close.className = close.className.replace('d-none', '');
      }

      // モックエリアの高さを取得し隠す
      const mock = parent.querySelector('.mock');
      let height = 0;
      if(mock){
        height = (mock as HTMLParagraphElement).offsetHeight;
        console.log('height', height)
        mock.className += ' d-none';
      }

      // テキストエリアを表示し編集可能にする
      const textarea = parent.querySelector('.text');
      if(textarea){
        (textarea as HTMLTextAreaElement).style.height = `${height}px`;
        (textarea as HTMLTextAreaElement).disabled = false;
        (textarea as HTMLTextAreaElement).className = (textarea as HTMLTextAreaElement).className.replace('d-none', '');
        (textarea as HTMLTextAreaElement).focus();
      }
    }
  }

  const onClickCloseMessage = (event: React.MouseEvent, lang: string) => {
    // 閉じるボタンを隠す
    event.currentTarget.className += ' d-none';

    const parent = event.currentTarget.parentElement;
    if(parent){
      // 保存ボタンを隠す
      const save = parent.querySelector('.save');
      if(save){
        save.className += ' d-none';
      }
      // 開くボタンを表示
      const open = parent.querySelector('.open');
      if(open){
        open.className = open.className.replace('d-none', '');
      }

      //テキストエリアを編集不可にする＆テキストをもとに戻して非表示
      const textarea = parent.querySelector('.text');
      if(textarea){
        (textarea as HTMLInputElement).disabled = true;
        (textarea as HTMLInputElement).value = text;
        (textarea as HTMLTextAreaElement).className += ' d-none';
      }

      // モックエリアを表示
      const mock = parent.querySelector('.mock');
      if(mock){
        mock.className = mock.className.replace('d-none', '');
      }
    }
  }

  const onClickEditMessage = (event: React.MouseEvent, lang: string) => {
    // 保存ボタンを隠す
    event.currentTarget.className += ' d-none';

    const parent = event.currentTarget.parentElement;
    if(parent){
      // 閉じるボタンを隠す
      const close = parent.querySelector('.close');
      if(close){
        close.className += ' d-none';
      }
      // 開くボタンを表示
      const open = parent.querySelector('.open');
      if(open){
        open.className = open.className.replace('d-none', '');
      }

      //テキストエリアを編集不可にする＆更新して非表示
      const textarea = parent.querySelector('.text');
      if(textarea){
        (textarea as HTMLInputElement).disabled = true;
        (textarea as HTMLTextAreaElement).className += ' d-none';
        onEdit((textarea as HTMLInputElement).value);
      }

      // モックエリアを更新して表示
      const mock = parent.querySelector('.mock');
      if(mock){
        mock.innerHTML = (textarea as HTMLInputElement).value;
        mock.className = mock.className.replace('d-none', '');
      }
    }
  }

  return (
      <div key={text} title={t('メッセージを編集...')} className='d-flex gap-2 align-items-center message-line'>
        <Flag
            loading='lazy'
            className='border'
            language={LanguageList[lang as ILanguages]} />
        <p className='ml-2 form-control message-textarea mock'>{text}</p>
        <textarea
            disabled={true}
            className='ml-2 form-control message-textarea text d-none'
            defaultValue={text}
        />
        <button
            onClick={(event) => onClickOpenMessage(event)}
            className='meeting-edit-button open'><i className="bi bi-pencil-fill"></i></button>
        <button
            onClick={(event) => onClickEditMessage(event, lang)}
            className='meeting-edit-button save d-none'><i className="bi bi-check-lg"></i></button>
        <button
            onClick={(event) => onClickCloseMessage(event, lang)}
            className='meeting-edit-button close d-none'><i className="bi bi-x-lg"></i></button>
      </div>
  )
};
