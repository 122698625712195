import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";

export const SourceWords = ({index, sourceWord, onUpdate, onDelete}: { index: number, sourceWord: string, onUpdate: (index: number, variant: string) => Promise<boolean>, onDelete: (index: number) => Promise<boolean> }) => {
    const {t} = useTranslation()

    const [isActive, setIsActive] = useState(false);
    const [sourceWordValue, setSourceWordValue] = useState(sourceWord);

    const containerRef = useRef<HTMLLIElement>(null);
    const sourceWordInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const containerElement = containerRef.current;
        if(!containerElement){
            return;
        }
        const handleFocusOut = (event: FocusEvent) => {
            if (!containerRef.current?.contains(event.relatedTarget as Node)) {
                setIsActive(false)
            }
        }
        containerElement.addEventListener('focusout', handleFocusOut);

        return () => {
            containerElement.removeEventListener('focusout', handleFocusOut);
        };
    }, []);

    const onChangeSourceWordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSourceWordValue(event.currentTarget.value);
    };

    const onFocusSourceWordInput = (event: React.FormEvent<HTMLInputElement>) => {
        setIsActive(true)
    };

    const onclickChangeSourceWord = async () => {
        if(!sourceWordInputRef.current){
            return;
        }
        await onUpdate(index, sourceWordInputRef.current.value);
        setIsActive(false)
    }

    const onclickDeleteSourceWord = async () => {
        if(!sourceWordInputRef.current){
            return;
        }
        await onDelete(index);
    }

    return (
        <li className="list-group-item"
            ref={containerRef}
        >
            <div className="row g-3 w-100 pe-2">
                <div className="col-10 pb-2">
                    <input ref={sourceWordInputRef}
                           className="form-control form-control-sm"
                           type="text"
                           value={sourceWordValue}
                           onFocus={onFocusSourceWordInput}
                           onChange={onChangeSourceWordInput} />
                </div>
                <div className="col-1">
                    <button type="button"
                            className={`pt-1 btn btn-success w-100 ${isActive ? '' : 'visually-hidden'}`}
                            onClick={onclickChangeSourceWord}
                    >{t('変更')}</button>
                </div>
                <div className="col-1">
                    <button
                        type="button"
                        className={`pt-1 btn btn-danger w-100 ${isActive ? '' : 'visually-hidden'}`}
                        onClick={onclickDeleteSourceWord}
                    >{t('削除')}</button>
                </div>
            </div>
        </li>
    )
}
