type OnTriggerFn<T> = (data?: T) => void

export class DelayTask<T> {
  private data?: T = undefined
  private timeoutTask?: NodeJS.Timeout
  private delayTime: number

  constructor(delayTime = 200) {
    this.delayTime = delayTime
  }

  onTrigger?: OnTriggerFn<T>

  trigger = (triggerData?: T) => {
    // save last triggered data
    this.data = triggerData

    clearTimeout(this.timeoutTask)
    this.timeoutTask = setTimeout(() => {
      this.timeoutTask = undefined
      // call onTrigger with last triggered data
      this.onTrigger && this.onTrigger(this.data)
    }, this.delayTime)
  }

  cancel = () => {
    clearTimeout(this.timeoutTask)
  }

  isRunning = () => {
    return !!this.timeoutTask
  }
}
