import { useEffect, useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite"

import Api from "../../api/Api"
import { LIST_OF_AVAILABLE_PLANS } from "../../constant/Endpoints"
import UserInfo from "../../store/UserInfo"
import { Avatar } from "../../view/Avatar"
import { BUTTON_MODES, show } from "../../view/PopupEvent"
import styles from "./Subscribe.module.css"
import { TPlan } from "../../store/UserInfo"
import { payjp } from "../../api/Payjp"
import { PlanItem } from "./component/PlanItem"
import { CardItem, type CardTokenResult } from "./component/CardItem"
import { CardInputForm } from "./component/CardInputForm"
import { PlanSelection } from "./component/PlanSelection"
import { _parseQueryParam } from "../../util/Util"
import { sentryLog } from "../../util/sentry";

export const Subscribe = observer(() => {
  const { t } = useTranslation()
  const [openTab, setOpenTab] = useState<'card' | 'plan' | 'confirm'>('plan')
  const [cardToken, setCardTokens] = useState<CardTokenResult | null>(null)
  const [selectedPlan, setSelectedPlan] = useState<string>('')
  const [planOptions, setPlanOptions] = useState<Array<TPlan>>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isAgreed, setIsAgreed] = useState<boolean>(false)

  const history = useHistory()
  const [pJPElement, setPJPElement] = useState<any>(null)

  useEffect(() => {
    setPJPElement(payjp.elements())
  }, [])

  const { loggedIn } = UserInfo

  // plan information from query string
  // this information is only available if the page is displayed automatically after email verification
  const planIdFromQuery = useMemo(() => {
    const plan = loggedIn ? _parseQueryParam('plan', false) : ''
    return Array.isArray(plan) ? plan[0] : plan
  }, [loggedIn])

  const planFromQuery = useMemo(() => {
    return planOptions.find((plan) => planIdFromQuery === plan.id)
  }, [planOptions, planIdFromQuery])

  useEffect(() => {
    // only switch to 'card' tab automatically if there is plan id from query string and plan list has been loaded
    if (planOptions.length && planIdFromQuery) {
      setSelectedPlan(planIdFromQuery)
      setOpenTab('card')
    }
  }, [planIdFromQuery, planOptions.length])

  const submit = async () => {
    if (selectedPlan !== "" && cardToken != null) {
      setIsSubmitting(true)
      const [isOk, msg] = await UserInfo.subscribe(selectedPlan, cardToken.token)
      if (!isOk) {
        history.replace('/settings')
        await show({
          content: (<div>
            <p>{t('申請に失敗しました。')}</p>
            <p>{msg}</p>
          </div>),
          btnMode: BUTTON_MODES.OK,
        })
      } else {
        history.replace('/')
        await show({
          content: t('申請に成功しました。一度ログアウトします。'),
          btnMode: BUTTON_MODES.OK,
        })
      }
    }
  }

  const submitCardToken = (c: CardTokenResult) => {
    setCardTokens({ ...c })
    setOpenTab('confirm')
  }

  const submitPlan = (sid: string) => {
    setSelectedPlan(sid)
    setOpenTab('card')
  }

  useEffect(() => {
    Api.get(LIST_OF_AVAILABLE_PLANS)
      .then((response) => {
        if (response.data?.success) {
          // pre-processing
          const plans = (response.data.plans || []).filter((p: any) => !!p.amountJpy)

          // add free plan
          const freePlan = {
            id: "FREE",
            amountJpy: 0,
            isMonthly: true,
            maxUseTime: 0,
            name: "FREE",
            payJpId: "donut_AI_0",
            productCode: "FREE",
            trialDays: 0,
            // createdAt: "2024-05-22T01:10:45.449Z",
            // updatedAt: "2024-05-22T01:32:46.375Z",
          }

          setPlanOptions([freePlan, ...plans])
        }
      })
      .catch((_value) => {
        // log to sentry
        sentryLog(_value)
        console.error("Value Does not exist")
      })
  }, [])

  return (<div className={"container p-4 p-md-4 text-donut " + styles.rootStyle}>
    {UserInfo.loggedIn ? (
      <>
        {/* title */}
        <div className='row mb-3'>
          <div className='col-auto h1 text-primary mb-0'>
            <b>{t('新しく契約する')}</b>
          </div>
        </div>

        {/* current user */}
        <div className="my-4">
          <h4>{t('以下のユーザーで新しい契約を結びます。')}</h4>
          <div className="my-2 d-flex">
            <Avatar />
          </div>
        </div>

        {/* card input */}
        {openTab === 'card' && (
          <>
            {!!planFromQuery && (
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <h3 className="my-2">{t('プラン')}</h3>
                    <PlanItem p={planFromQuery} children={null} />
                  </div>
                </div>
              </div>
            )}
            <CardInputForm payJPElements={pJPElement} setCardToken={submitCardToken} />
          </>
        )}
      </>
    ) : (
      <Link to="/" className="mx-2 link-secondary">
        {t('戻る')}
      </Link>
    )}
    {openTab === 'plan' ? (
      planOptions.length ? (
        <PlanSelection plans={[...(UserInfo.plans || planOptions)]} selectedPlanId={selectedPlan} setSelectedPlan={submitPlan} />
      ) : (
        <div className='row justify-content-center'>
          <div className='col-auto py-5'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </div>
      )
    ) : null}
    {openTab === 'confirm' ? (
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h4 className="my-2">{t('プラン')}</h4>
            <div className="my-4">
              <PlanItem p={planOptions.find(n => n.id === selectedPlan)}>
                <button onClick={() => setOpenTab('plan')} disabled={isSubmitting} className="btn btn-secondary">
                  {t('変更する')}
                </button>
              </PlanItem>
            </div>
          </div>
          <div className="col-4">
            <h4 className="my-2">{t('クレジットカード')}</h4>
            <div className="my-4">
              <CardItem card={cardToken!}>
                <button onClick={() => setOpenTab('card')} disabled={isSubmitting} className="btn btn-secondary">
                  {t('変更する')}
                </button>
              </CardItem>
            </div>
          </div>
        </div>

        <div className="form-check  my-2">
          <label className="form-check-label">
            {t('私は「利用規約・プライバシーポリシー」に書かれている内容に同意します。')}
          </label>
          <input className="form-check-input"
            type="checkbox" checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />
        </div>

        <button className="btn btn-primary" disabled={!isAgreed || isSubmitting} onClick={submit}>
          {t('申請する')}
        </button>
      </div>
    ) : null}
  </div>)
})
