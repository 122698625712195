import { useState } from 'react'
import RemoveMarkdown from 'remove-markdown'

type UseCopyToClipboardOut = [boolean, () => void]

export const useCopyToClipboard = (value: string, timeout: number = 1000): UseCopyToClipboardOut => {
  const [copiedTimer, setCopiedTimer] = useState<NodeJS.Timeout|null>(null)
  const performCopy = () => {
    if(copiedTimer) {
      clearTimeout(copiedTimer)
    }

    navigator.clipboard.writeText(RemoveMarkdown(value).replaceAll('*', ''))

    setCopiedTimer(setTimeout(() => {
      setCopiedTimer(null)
    }, timeout))
  }

  const isCopying = copiedTimer != null
  return [isCopying, performCopy]
}

