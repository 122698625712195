import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, addDoc, where, limit } from 'firebase/firestore'

import { firestore } from '../firebase'

export const MEETING_STATES = {
  WAITING: 'waiting',
  INCALL: 'incall',
  COMPLETED: 'completed',
} as const

export type SttDictionariesFirebaseData = {
  id?: string;
  targetWord: string;
  sourceWords?: string[];
};

export const getMeetingByTitle = (user: string | number | undefined, meetingTitle: string) => {
  const meetings = collection(firestore, `users/${user}/meetings`)
  const q = query(meetings, where('title', '==', meetingTitle))
  return getDocs(q)
}

export const getMeeting = (user: string | number | undefined, meeting: string) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}`)
  return getDoc(m)
}

export const getMeetings = (user: string | number | undefined) => {
  const meetings = collection(firestore, `users/${user}/meetings`)
  const q = query(meetings, orderBy('createdAt', 'desc'))
  return getDocs(q)
}

export const deleteMeeting = (user: string | number | undefined, meeting: string) => {
  // TODO: delete messages, because Firestore does not automatically delete sub collections

  // delete meeting
  const m = doc(firestore, `users/${user}/meetings/${meeting}`)
  return deleteDoc(m)
}

export const updateMeeting = (user: string | number | undefined, meeting: string, data: any) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}`)
  return updateDoc(m, data)
}

export const getMeetingMessages = (user: string | number | undefined, meeting: string, itemLimit: number = -1) => {
  const messages = collection(firestore, `users/${user}/meetings/${meeting}/messages`)
  const queries = []

  // TODO TODO TODO TODO: This is a temporary fix for
  // really huge document fetching problem.
  // THIS DOES NOT SOLVE THE BASE PROBLEM AND THUS NEEDS TO BE REVISITED ASAP.
  queries.push(orderBy('createdAt', 'asc'))
  if (itemLimit > 0) {
    queries.push(limit(itemLimit))
  }

  const q = query(messages, ...queries)
  return getDocs(q)
}

export const getMeetingMessage = (user: string | number | undefined, meeting: string, message: string) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}/messages/${message}`)
  return getDoc(m)
}

export const updateMeetingMessage = (user: string | number | undefined, meeting: string, message: string, data: any) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}/messages/${message}`)
  return updateDoc(m, data)
}

export const deleteMeetingMessage = async (user: string | number | undefined, meeting: string, message: string) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}/messages/${message}`)
  return deleteDoc(m)
}

export const updateMeetingMessageText = async (user: string | number | undefined, meeting: string, message: string, messages: { [lang: string]: string }) => {
  const doc = await getMeetingMessage(user, meeting, message);
  const data = doc.data();
  if(!data || !data.messages){
    return;
  }
  for (const [lang, text] of Object.entries(messages)) {
    if(data && data.messages[lang]){
      data.messages[lang] = text;
    }
  }
  await updateMeetingMessage(user, meeting, message, data);
}

export const getSystemSttDictionaries = async (): Promise<SttDictionariesFirebaseData[]> => {
  const sttDictionaries = collection(firestore, `config/general/sttDictionaries`)

  const queries = []
  queries.push(orderBy('targetWord', 'asc'))

  const q = query(sttDictionaries, ...queries)
  const snap = await getDocs(q);

  const arr: SttDictionariesFirebaseData[] = [];
  snap.docs.forEach((doc) => {
    const sttDictionariesData = doc.data() as SttDictionariesFirebaseData
    arr.push(sttDictionariesData);
  });
  return arr;
}

export const getUserSttDictionaries = async (user: string | number | undefined): Promise<SttDictionariesFirebaseData[]> => {
  const sttDictionaries = collection(firestore, `users/${user}/sttDictionaries`)

  const queries = []
  queries.push(orderBy('targetWord', 'asc'))

  const q = query(sttDictionaries, ...queries)
  const snap = await getDocs(q);

  const arr: SttDictionariesFirebaseData[] = [];
  snap.docs.forEach((doc) => {
    const sttDictionariesData = doc.data() as SttDictionariesFirebaseData
    arr.push({...sttDictionariesData, id: doc.id});
  });
  return arr;
}

export const createUserSttDictionaries = (user: string | number | undefined, data: SttDictionariesFirebaseData) => {
  const sttDictionaries = collection(firestore, `users/${user}/sttDictionaries`)
  return addDoc(sttDictionaries, data);
}

export const updateUserSttDictionaries = (user: string | number | undefined, data: SttDictionariesFirebaseData) => {
  const m = doc(firestore, `users/${user}/sttDictionaries/${data.id}`)
  return updateDoc(m, data)
}

export const deleteUserSttDictionaries = (user: string | number | undefined, id: string) => {
  const m = doc(firestore, `users/${user}/sttDictionaries/${id}`)
  return deleteDoc(m)
}
