import './Avatar.css'

import { observer } from 'mobx-react-lite'

import UserInfo from '../store/UserInfo'

export const Avatar = observer(() => {
  return (
    <div className='avatar-container d-flex align-items-center'>
      <div className='a-img d-flex align-items-center justify-content-center text-white'>
        <span>{`${UserInfo.displayName || UserInfo.email || UserInfo.id || ' '}`.charAt(0).toUpperCase()}</span>
      </div>
      <div className='ms-3' style={{ maxWidth: 200 }}>
        <div className='text-donut-dark h4 mb-0 text-ellipsis'>{UserInfo.displayName}</div>
        <div className='text-donut text-ellipsis'><small>{UserInfo.email}</small></div>
      </div>
    </div>
  )
})
