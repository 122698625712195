import { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import { type ILanguageObject } from '../constant/Languages'
import './Flag.css'

interface FlagProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  language: ILanguageObject
}

export const Flag = ({ className, language, ...props }: FlagProps) => {
  return (
    <img className={`flag ${className || ''}`} src={language?.img}
      alt={language?.sttLanguageCode} {...props} />
  )
}
